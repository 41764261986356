<template>
  <footer
    class="flex items-center justify-center bg-grey-50"
    :class="footerVisibilityClasses"
  >
    <BaseContent>
      <div class="grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-5">
        <div
          class="col-span-2 grid grid-cols-2 gap-8 md:col-span-1 md:grid-cols-1"
        >
          <div class="flex flex-col gap-3">
            <img
              :src="ogLogoWithText"
              loading="lazy"
              alt="Officeguru logo - letter O and G intertwining into an infinity symbol"
              class="w-[120px]"
              width="120"
              height="27"
            />
            <p class="text-body text-grey-500">Bryggervangen 55, 4. tv.</p>
            <p class="text-body text-grey-500">2100 København Ø</p>
            <p class="text-body text-grey-500">CVR 33070691</p>
          </div>
          <div class="flex flex-col gap-3">
            <p class="flex items-center gap-2 text-body">
              <BaseIcon icon="email" class="text-brand-600" :size="14" />
              <a href="mailto:contact@officeguru.dk">contact@officeguru.dk</a>
            </p>
            <p class="flex items-center gap-2 text-body">
              <BaseIcon icon="phone" class="text-brand-600" :size="14" />
              <a href="tel:+4543991529"> +45 4399 1529 </a>
            </p>
          </div>
        </div>
        <div class="col-span-2 grid grid-cols-2 gap-8">
          <div
            v-for="(column, index) in columns.slice(0, 2)"
            :key="index"
            class="flex flex-col gap-3"
          >
            <p class="text-body font-bold text-grey-900">
              {{ column.headline }}
            </p>
            <NuxtLink
              v-for="(link, idx) in column.links"
              :key="idx"
              :to="link.link"
              class="text-body text-grey-900"
              >{{ link.link_text }}</NuxtLink
            >
          </div>
        </div>
        <div
          class="col-span-2 col-start-auto grid grid-cols-2 gap-8 md:col-start-2 lg:col-start-auto"
        >
          <div
            v-for="(column, index) in columns.slice(2, 4)"
            :key="index"
            class="flex flex-col gap-3"
          >
            <p class="text-body font-bold text-grey-900">
              {{ column.headline }}
            </p>
            <NuxtLink
              v-for="(link, linkIndex) in column.links"
              :key="linkIndex"
              :to="link.link"
              class="text-body text-grey-900"
              >{{ link.link_text }}</NuxtLink
            >
          </div>
        </div>
      </div>

      <div class="my-10 h-px w-full bg-grey-200" />

      <div class="flex flex-col gap-8">
        <div
          class="flex flex-col items-center justify-between gap-8 md:flex-row"
        >
          <div>
            <TrustBox class="md:-ml-5" />
          </div>
          <div class="flex items-center gap-6">
            <NuxtLink
              aria-label="Facebook"
              class="leading-[0]"
              to="https://www.facebook.com/officegurudk"
              ><BaseIcon icon="facebook" :size="24"
            /></NuxtLink>
            <NuxtLink
              aria-label="LinkedIn"
              class="leading-[0]"
              to="https://www.linkedin.com/company/officeguru-a-s"
              ><BaseIcon icon="linkedin" :size="24"
            /></NuxtLink>
            <LangSwitch />
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-between gap-8 md:flex-row md:items-end"
        >
          <div class="typo-body flex items-end gap-4 text-grey-500">
            <Gazelle class="h-10 w-auto" /><span class="-mb-1">{{
              $t("components.footer.gazelle_winner")
            }}</span>
          </div>
          <div class="typo-body -mb-1 text-grey-500">
            © 2023 Officeguru A/S
          </div>
        </div>
      </div>
    </BaseContent>
  </footer>
</template>

<script setup lang="ts">
import ogLogoWithText from "~/assets/og-logo-with-text.svg?url";
import { useCMSStore } from "~/store/CMSStore";
import { useUIStore } from "~/store/UIStore";
import Gazelle from "~/assets/gazelle.svg?component";

const cmsStore = useCMSStore();
const uiStore = useUIStore();

const columns = cmsStore?.cmsValues?.footer_columns || [];
defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
});

const footerVisibilityClasses = computed(() => {
  return uiStore.mainNavIsShown ? "translate-y-0" : "translate-y-[1000%]";
});
</script>
