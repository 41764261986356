<template>
  <BaseContent :style="backgroundStyle">
    <div class="flex flex-col" :class="[verticalPadding]">
      <h2 v-if="content.header_text" class="typo-h2 mb-12">
        {{ content.header_text }}
      </h2>
      <div class="flex w-full flex-col gap-12 md:flex-row">
        <div
          v-for="column in props.content.columns"
          :key="column.id"
          class="flex flex-1 flex-col items-start"
        >
          <!-- done -->
          <CmsImage
            v-if="column?.image?.url"
            :src="column?.image?.url"
            :alt="column?.image?.alt"
            :width="column?.image?.width"
            :height="column?.image?.height"
            :class="{ [imageRounded]: !!imageRounded }"
            class="mb-4 lg:mb-6"
            :max-width="542"
          />
          <h3 class="typo-h3 mb-3">
            {{ column.header_text }}
          </h3>
          <div class="typo-body-xl mb-6 text-grey-700 lg:mb-8">
            {{ column.description }}
          </div>
          <div
            v-if="column.button_link && column.button_label"
            class="flex flex-1 flex-col-reverse"
          >
            <BaseButton :href="column.button_link" type="outline" size="large">
              {{ column.button_label }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
import { useVerticalPadding } from "~/components/cms-blocks/components/use-vertical-padding";
import { useImageRounded } from "~/components/cms-blocks/components/use-image-rounded";
import { useBackgroundColor } from "~/components/cms-blocks/components/use-background-color";
import { useBackgroundImage } from "~/components/cms-blocks/components/use-background-image";

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
});

const verticalPadding = useVerticalPadding(props);
const imageRounded = useImageRounded(props);
const backgroundColor = useBackgroundColor(props);
const backgroundImage = useBackgroundImage(props);
const backgroundStyle = backgroundImage.value
  ? backgroundImage.value
  : backgroundColor.value;
</script>
