function preloadImage(
  path: string,
  { priority = "high" }: { priority?: "high" | "low" | "auto" } = {},
) {
  useHead(() => ({
    link: [
      {
        rel: "preload",
        as: "image",
        fetchpriority: priority,
        href: (path || "").replace("http://", "https://"),
      },
    ],
  }));
}

export { preloadImage };
