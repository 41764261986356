<template>
  <div
    v-if="info && !hide"
    data-cy="cmp-pagination"
    class="flex flex-col flex-wrap items-center"
  >
    <div
      v-if="hasMoreThanOnePage"
      data-cy="cmp-pagination__pages"
      class="flex-1 shrink-0 basis-auto"
    >
      <!-- Full view -->
      <ul class="flex flex-1 shrink-0 basis-auto justify-center">
        <li
          data-cy="cmp-pagination__pages__prev"
          class="flex cursor-pointer items-center justify-center p-2"
          :class="{ hidden: !hasPrev }"
          @click="goToPrev"
        >
          <span
            class="flex h-[32px] w-[32px] select-none items-center justify-center rounded-full text-center text-body text-grey-900 transition-all duration-200 ease-in-out"
          >
            <BaseIcon icon="caret-down" :size="14" class="rotate-90" />
          </span>
        </li>

        <div
          v-if="simplified"
          class="flex flex-1 shrink-0 items-center justify-center px-0 py-3 text-body text-grey-600"
        >
          {{
            $t("shared.n_current_of_n_total", {
              current: currentPage,
              total: lastPage,
            })
          }}
        </div>

        <div v-else class="flex flex-1 shrink-0 basis-auto justify-center">
          <li
            v-for="page in range.startRange"
            :key="page"
            data-cy="cmp-pagination__pages__number"
            class="flex cursor-pointer items-center justify-center p-2"
            @click="goToPage(page)"
          >
            <span
              class="flex h-[32px] w-[32px] select-none items-center justify-center rounded-full text-center text-body text-grey-900 transition-all duration-200 ease-in-out"
              :class="{ 'bg-grey-900 text-white': page === currentPage }"
              >{{ page }}</span
            >
          </li>
          <li
            v-if="needsStartDots"
            class="flex items-center justify-center p-2"
          >
            <span
              class="flex h-[32px] w-[32px] select-none items-center justify-center rounded-full text-center text-body text-grey-900 transition-all duration-200 ease-in-out"
              >...</span
            >
          </li>
          <li
            v-for="page in range.midRange"
            :key="page"
            data-cy="cmp-pagination__pages__number"
            class="flex cursor-pointer items-center justify-center p-2"
            @click="goToPage(page)"
          >
            <span
              class="flex h-[32px] w-[32px] select-none items-center justify-center rounded-full text-center text-body text-grey-900 transition-all duration-200 ease-in-out"
              :class="{ 'bg-grey-900 text-white': page === currentPage }"
              >{{ page }}</span
            >
          </li>
          <li v-if="needsEndDots" class="flex items-center justify-center p-2">
            <span
              class="flex h-[32px] w-[32px] select-none items-center justify-center rounded-full text-center text-body text-grey-900 transition-all duration-200 ease-in-out"
              >...</span
            >
          </li>
          <li
            v-for="page in range.endRange"
            :key="page"
            data-cy="cmp-pagination__pages__number"
            class="flex cursor-pointer items-center justify-center p-2"
            @click="goToPage(page)"
          >
            <span
              class="flex h-[32px] w-[32px] select-none items-center justify-center rounded-full text-center text-body text-grey-900 transition-all duration-200 ease-in-out"
              :class="{ 'bg-grey-900 text-white': page === currentPage }"
              >{{ page }}</span
            >
          </li>
        </div>

        <li
          data-cy="cmp-pagination__pages__next"
          class="flex cursor-pointer items-center justify-center p-2"
          :class="{ hidden: !hasNext }"
          @click="goToNext"
        >
          <span
            class="flex h-[32px] w-[32px] select-none items-center justify-center rounded-full text-center text-body text-grey-900 transition-all duration-200 ease-in-out"
          >
            <BaseIcon icon="caret-down" :size="14" class="-rotate-90" />
          </span>
        </li>
      </ul>
    </div>
    <div
      v-if="total && !simplified"
      data-cy="cmp-pagination__info"
      class="mt-2 flex-1 shrink-0 text-body text-grey-600"
    >
      {{
        $t("shared.showing_n_to_m_of_total_resource", {
          from,
          to,
          total,
          resource: translatedResourceName,
        })
      }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import last from "lodash/last";
import first from "lodash/first";
import difference from "lodash/difference";
import _range from "lodash/range";

interface Props {
  info: OgApi.ListResponseMeta;
  hide?: boolean;
  simplified?: boolean;
  translatedResourceName?: string;
}

function rangeIncludingStop(start: number, stop: number, step: number = 1) {
  return _range(start, stop + 1, step);
}
const props = withDefaults(defineProps<Props>(), {
  translatedResourceName: "",
  hide: false,
  simplified: false,
});

const emit = defineEmits(["click"]);

const currentPage = computed(() => props.info && props.info.current_page);
const lastPage = computed(() => props.info && props.info.last_page);
const from = computed(() => props.info && props.info.from);
const to = computed(() => props.info && props.info.to);
const total = computed(() => props.info && props.info.total);
const range = computed<{
  startRange: number[];
  midRange: number[];
  endRange: number[];
}>(() => {
  // just show all if we have less than 6 pages
  if (lastPage.value <= 5) {
    return {
      startRange: rangeIncludingStop(1, lastPage.value),
      midRange: [],
      endRange: [],
    };
  }

  let startRange = rangeIncludingStop(1, 2);
  let endRange = rangeIncludingStop(lastPage.value - 1, lastPage.value);
  const midRange = rangeIncludingStop(
    Math.max(1, currentPage.value - 1),
    Math.min(last(endRange) || 0, currentPage.value + 1),
  );

  startRange = difference(startRange, midRange);
  endRange = difference(endRange, midRange);

  return {
    startRange,
    midRange,
    endRange,
  };
});
const needsStartDots = computed(() => {
  const { startRange, midRange } = range.value;
  if (!(startRange.length > 0 && midRange.length > 0)) return false;

  return first(midRange) !== (last(startRange) as number) + 1;
});
const needsEndDots = computed(() => {
  const { midRange, endRange } = range.value;

  if (!(endRange.length > 0 && midRange.length > 0)) return false;

  return first(endRange) !== (last(midRange) as number) + 1;
});
const hasPrev = computed(() => !(currentPage.value <= 1));
const hasNext = computed(() => currentPage.value !== lastPage.value);
const hasMoreThanOnePage = computed(() => lastPage.value > 1);

function goToPrev() {
  if (!hasPrev) return;
  emit("click", currentPage.value - 1);
}

function goToNext() {
  if (!hasNext) return;
  emit("click", currentPage.value + 1);
}

function goToPage(page: number) {
  if (currentPage.value === page) return;

  emit("click", page);
}
</script>
