<template>
  <BaseContent
    content-classes="grid grid-cols-2 gap-8"
    itemscope
    itemtype="https://schema.org/Blog"
  >
    <BlogPreview
      v-for="post in sortedBlogPosts"
      :key="post.id"
      :post="post"
      class="overflow-hidden rounded-2xl border border-grey-200"
    />
  </BaseContent>
</template>
<script lang="ts" setup>
import BlogPreview from "~/pages/components/blog/blog-preview.vue";

async function fetchBlogPosts() {
  const response: any = await $fetch(
    `${
      useRuntimeConfig().public.cmsUrl
    }api/collections/blogs/entries?limit=9999`,
  );
  return response.data.reverse();
}

const blogPosts: Ref<BlogPost[]> = useAsyncData(
  "blogPosts",
  fetchBlogPosts,
).data;

const sortedBlogPosts = computed(() => {
  return (
    blogPosts.value?.sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    }) || []
  );
});
</script>
