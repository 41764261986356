<template>
  <div class="flex">
    <component
      :is="icon"
      v-for="(icon, index) in stars"
      :key="index"
      :class="calculatedSizeStyle"
    />
  </div>
</template>

<script lang="ts" setup>
import SE from "~/assets/icons/star-empty.svg";
import SH from "~/assets/icons/star-half.svg";
import SF from "~/assets/icons/star-full.svg";

const props = defineProps({
  rating: {
    type: [Number, String],
    required: true,
  },
  size: {
    type: String,
    default: "medium",
    validator: (value: string) => ["small", "medium", "large"].includes(value),
  },
});

const calculatedSizeStyle = computed(
  () => sizeStyling[props.size as keyof typeof sizeStyling],
);

const sizeStyling = {
  small: "h-2 w-2",
  medium: "h-4 w-4",
  large: "h-6 w-6",
};

const STAR_MAP = [
  { threshold: 0.2, stars: [SE, SE, SE, SE, SE] },
  { threshold: 0.7, stars: [SH, SE, SE, SE, SE] },
  { threshold: 1.2, stars: [SF, SE, SE, SE, SE] },
  { threshold: 1.7, stars: [SF, SH, SE, SE, SE] },
  { threshold: 2.2, stars: [SF, SF, SE, SE, SE] },
  { threshold: 2.7, stars: [SF, SF, SH, SE, SE] },
  { threshold: 3.2, stars: [SF, SF, SF, SE, SE] },
  { threshold: 3.7, stars: [SF, SF, SF, SH, SE] },
  { threshold: 4.2, stars: [SF, SF, SF, SF, SE] },
  { threshold: 4.7, stars: [SF, SF, SF, SF, SH] },
  { threshold: Infinity, stars: [SF, SF, SF, SF, SF] },
];

const stars = computed(() => {
  return STAR_MAP.find((item) => props.rating <= item.threshold)?.stars;
});
</script>
