import revive_payload_client_4sVQNw7RlN from "/home/forge/officeguru.dk/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/forge/officeguru.dk/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/forge/officeguru.dk/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/home/forge/officeguru.dk/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/forge/officeguru.dk/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/forge/officeguru.dk/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/home/forge/officeguru.dk/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_vyNBGOI7EC from "/home/forge/officeguru.dk/node_modules/@nuxtjs/i18n/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/forge/officeguru.dk/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_language_P5vJH6FoIu from "/home/forge/officeguru.dk/plugins/01.language.ts";
import _02_ofetch_DyGueH6lxo from "/home/forge/officeguru.dk/plugins/02.ofetch.ts";
import _03_routes_JGsBhqKJNW from "/home/forge/officeguru.dk/plugins/03.routes.ts";
import _04_redirects_BcFXf6GePi from "/home/forge/officeguru.dk/plugins/04.redirects.ts";
import _05_populate_cms_store_7kp0Un03Sg from "/home/forge/officeguru.dk/plugins/05.populate-cms-store.ts";
import _06_recaptcha_MfmX03vnje from "/home/forge/officeguru.dk/plugins/06.recaptcha.ts";
import _07_posthog_5lPdPvjemW from "/home/forge/officeguru.dk/plugins/07.posthog.ts";
import _08_vue_toastify_d8huc9evpU from "/home/forge/officeguru.dk/plugins/08.vue-toastify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_vyNBGOI7EC,
  chunk_reload_client_UciE0i6zes,
  _01_language_P5vJH6FoIu,
  _02_ofetch_DyGueH6lxo,
  _03_routes_JGsBhqKJNW,
  _04_redirects_BcFXf6GePi,
  _05_populate_cms_store_7kp0Un03Sg,
  _06_recaptcha_MfmX03vnje,
  _07_posthog_5lPdPvjemW,
  _08_vue_toastify_d8huc9evpU
]