import { ofetch } from "ofetch";
import { useAuthStore } from "~/store/AuthStore";

export default defineNuxtPlugin(() => {
  globalThis.$fetch = ofetch.create({
    onRequest: ({ options }) => {
      // @TODO: Make sure we only apply the site query to CMS API requests (i.e. request.includes(VITE_CMS_API_URL)))
      options.query = {
        ...options.query,
        token: globalThis.previewToken,
        site: globalThis.lang,
      };
    },
    onResponseError: (error) => {
      if (error.response.status === 401) {
        const { clear } = useAuthStore();
        clear();
      }
    },
  });
});
