<template>
  <BaseContent>
    <div
      class="w-full pb-10 pt-6"
      itemscope
      itemtype="https://schema.org/FAQPage"
    >
      <h2 class="typo-h2 mb-10 text-grey-900">
        {{ headline }}
      </h2>

      <div class="rounded-xl border border-grey-200">
        <details
          v-for="(item, index) in items"
          :key="index"
          class="group p-5"
          :class="{ 'border-b border-b-grey-200': index !== items.length - 1 }"
          itemscope
          itemtype="https://schema.org/Question"
          itemprop="mainEntity"
        >
          <summary
            class="typo-body-xl relative flex cursor-pointer select-none list-none items-center justify-between font-medium text-grey-700 marker:hidden"
            itemprop="name"
          >
            {{ item.question }}
            <BaseIcon
              icon="caret-down"
              :size="12"
              class="text-grey-400 transition-all group-open:rotate-180"
            />
          </summary>
          <div
            class="typo-body-xl mt-2 text-grey-700"
            itemprop="acceptedAnswer"
            itemscope
            itemtype="https://schema.org/Answer"
          >
            <div itemprop="text">
              <WysiwygRenderer :content="item.answer" />
            </div>
          </div>
        </details>
      </div>
    </div>
  </BaseContent>
</template>

<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  raw: {
    type: Object,
    required: true,
  },
});

const items = computed(() => props.content.items);
const headline = computed(() => props.content.headline);
</script>
