import posthog from "posthog-js";
import { useUserStore } from "~/store/UserStore";

export default defineNuxtPlugin((nuxtApp) => {
  const config = nuxtApp.$config as any;

  const PostHog = {
    init() {
      posthog.init(config.public.posthog.apiKey, {
        api_host: config.public.posthog.host,
        // capture_pageview: false
      });

      // Subscribe to the user store to identify the user when they log in
      useUserStore().$subscribe((_, state) => {
        state.user ? this.identify(state.user) : this.reset();
      });

      // Make sure that pageviews are captured with each route change
      const router = useRouter();
      router.afterEach((to) => {
        this.capture("$pageview", {
          current_url: to.fullPath,
        });
      });
    },

    get initialized(): boolean {
      return posthog.__loaded;
    },

    get id(): string | null {
      return this.initialized ? posthog.get_distinct_id() : null;
    },

    capture(event: string, properties?: object) {
      if (config.public.appEnv !== "production") {
        // eslint-disable-next-line no-console
        console.info("Capturing event with posthog. Payload: ", {
          event,
          properties,
        });
      }
      this.initialized && posthog.capture(event, properties);
    },

    identify(user: OgApi.User | { email: string }) {
      if (config.public.appEnv !== "production") {
        // eslint-disable-next-line no-console
        console.info("Indentifying with posthog. Payload: ", {
          email: user.email,
          user,
        });
        // eslint-disable-next-line no-console
        console.info("Aliasing current distinct id to user email. Payload: ", {
          email: user.email,
          id: posthog.get_distinct_id(),
        });
      }
      if (this.initialized) {
        // Get the current distinct id before indentifying
        const id = posthog.get_distinct_id();
        posthog.identify(user.email, user);
        // Attach the user's events to their new identity
        posthog.alias(user.email, id);
      }
    },

    reset(resetDeviceId?: boolean) {
      if (config.public.appEnv !== "production") {
        // eslint-disable-next-line no-console
        console.info("Resetting posthog connection");
      }

      this.initialized && posthog.reset(resetDeviceId);
    },
  };

  nuxtApp.provide("posthog", PostHog);
});
