// SEE https://github.com/nuxt-community/recaptcha-module/issues/123

export default defineNuxtPlugin((nuxtApp) => {
  const config = nuxtApp.$config;
  const { grecaptcha } = config.public;
  nuxtApp.provide("recaptcha", new ReCaptcha(grecaptcha));
});

const API_URL = "https://www.recaptcha.net/recaptcha/api.js";

// https://github.com/PierfrancescoSoffritti/light-event-bus.js/blob/master/src/EventBus.js
class EventBus {
  subscriptions: any;
  subscribe: any;
  publish: any;
  constructor() {
    this.subscriptions = {};

    this.subscribe = function subscribeCallbackToEvent(
      eventType: string,
      callback: any,
    ) {
      const id = Symbol("id");
      if (!this.subscriptions[eventType]) this.subscriptions[eventType] = {};
      this.subscriptions[eventType][id] = callback;
      return {
        unsubscribe: () => {
          delete this.subscriptions[eventType][id];
          if (
            Object.getOwnPropertySymbols(this.subscriptions[eventType])
              .length === 0
          ) {
            delete this.subscriptions[eventType];
          }
        },
      };
    };

    this.publish = function publishEventWithArgs(eventType: string, arg: any) {
      if (!this.subscriptions[eventType]) return;

      Object.getOwnPropertySymbols(this.subscriptions[eventType]).forEach(
        (key) => this.subscriptions[eventType][key](arg),
      );
    };
  }
}

class ReCaptcha {
  _elements: { script?: any; style?: any };
  _grecaptcha: any;
  _eventBus: any;
  _ready: boolean | null;
  hideBadge: boolean;
  language: string | undefined;
  siteKey: string;
  version: number;
  size: string | undefined;
  mode: string;

  constructor({
    hideBadge,
    language,
    mode,
    siteKey,
    version,
    size,
  }: {
    hideBadge: boolean;
    language: string | undefined;
    mode: string;
    siteKey: string;
    version: number;
    size: string | undefined;
  }) {
    if (!siteKey) {
      throw new Error("ReCaptcha error: No key provided");
    }

    if (!version) {
      throw new Error("ReCaptcha error: No version provided");
    }

    this._elements = {};
    this._grecaptcha = null;

    this._eventBus = null;
    this._ready = false;

    this.hideBadge = hideBadge;
    this.language = language;

    this.siteKey = siteKey;
    this.version = version;
    this.size = size;

    this.mode = mode;
  }

  destroy() {
    if (this._ready) {
      this._ready = false;

      const { head } = document;
      const { style } = this._elements;

      const scripts = [...document.head.querySelectorAll("script")].filter(
        (script) => script.src.includes("recaptcha"),
      );

      if (scripts.length) {
        scripts.forEach((script) => head.removeChild(script));
      }

      if (head.contains(style)) {
        head.removeChild(style);
      }

      const badge = document.querySelector(".grecaptcha-badge");
      if (badge) {
        badge.remove();
      }
    }
  }

  async execute(action: any) {
    try {
      await this.init();

      if ("grecaptcha" in window) {
        return this._grecaptcha.execute(this.siteKey, { action });
      }
    } catch (error) {
      throw new Error(`ReCaptcha error: Failed to execute ${error}`);
    }
  }

  getResponse(widgetId: string) {
    return new Promise((resolve, reject) => {
      if ("grecaptcha" in window) {
        if (this.size === "invisible") {
          this._grecaptcha.execute(widgetId);

          window.recaptchaSuccessCallback = (token: string) => {
            this._eventBus.publish("recaptcha-success", token);
            resolve(token);
          };

          window.recaptchaErrorCallback = (error: any) => {
            this._eventBus.publish("recaptcha-error", error);
            reject(error);
          };
        } else {
          const response = this._grecaptcha.getResponse(widgetId);

          if (response) {
            this._eventBus.publish("recaptcha-success", response);
            resolve(response);
          } else {
            const errorMessage = "Failed to execute";

            this._eventBus.publish("recaptcha-error", errorMessage);
            reject(errorMessage);
          }
        }
      }
    });
  }

  async init() {
    if (this._ready) {
      // make sure caller waits until recaptcha get ready
      return this._ready;
    }

    this._eventBus = new (EventBus as any)();
    this._elements = {
      script: document.createElement("script"),
      style: document.createElement("style"),
    };

    const { script, style } = this._elements;

    script.setAttribute("async", "");
    script.setAttribute("defer", "");

    const params = [];
    if (this.version === 3) {
      params.push("render=" + this.siteKey);
    }
    if (this.language) {
      params.push("hl=" + this.language);
    }

    let scriptUrl = API_URL;

    if (this.mode === "enterprise") {
      scriptUrl = scriptUrl.replace("api.js", "enterprise.js");
      params.push("render=" + this.siteKey);
    }

    script.setAttribute("src", scriptUrl + "?" + params.join("&"));

    window.recaptchaSuccessCallback = (token: string) =>
      this._eventBus.publish("recaptcha-success", token);
    window.recaptchaExpiredCallback = () =>
      this._eventBus.publish("recaptcha-expired");
    window.recaptchaErrorCallback = () =>
      this._eventBus.publish("recaptcha-error", "Failed to execute");

    this._ready = await new Promise((resolve, reject): void => {
      script.addEventListener("load", () => {
        if (this.version === 3 && this.hideBadge) {
          style.innerHTML = ".grecaptcha-badge { display: none }";
          document.head.appendChild(style);
        } else if (this.version === 2 && this.hideBadge) {
          // display: none DISABLES the spam checking!
          // ref: https://stackoverflow.com/questions/44543157/how-to-hide-the-google-invisible-recaptcha-badge
          style.innerHTML = ".grecaptcha-badge { visibility: hidden; }";
          document.head.appendChild(style);
        }

        this._grecaptcha = window.grecaptcha.enterprise || window.grecaptcha;
        this._grecaptcha.ready(resolve);
      });

      script.addEventListener("error", () => {
        document.head.removeChild(script);
        reject(new Error("ReCaptcha error: Failed to load script"));
        this._ready = null;
      });

      document.head.appendChild(script);
    });

    return this._ready;
  }

  on(event: any, callback: void) {
    return this._eventBus.subscribe(event, callback);
  }

  reset(widgetId: string) {
    if (this.version === 2 || typeof widgetId !== "undefined") {
      this._grecaptcha.reset(widgetId);
    }
  }

  render(reference: any, { sitekey, theme }: any) {
    return this._grecaptcha.render(reference.$el || reference, {
      sitekey,
      theme,
    });
  }
}
