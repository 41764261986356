<template>
  <div class="grid grid-cols-1 md:grid-cols-[auto,1fr] md:gap-6">
    <template v-if="!Array.isArray(service.content)">
      <!-- Example menu -->
      <p v-if="service.content?.example_menu" class="text-body text-grey-500">
        {{ $t("vendor_page.example_menu") }}
      </p>
      <NuxtLink
        v-if="service.content?.example_menu"
        :href="service.content.example_menu"
        target="_blank"
        class="mb-4 text-body font-medium text-brand-600 md:mb-0"
      >
        {{ $t("vendor_page.view") }}
        <BaseIcon icon="link-external" :size="14" />
      </NuxtLink>

      <!-- Price -->
      <p v-if="service.content?.price_range" class="text-body text-grey-500">
        {{ $t("vendor_page.price") }}
      </p>
      <p
        v-if="service.content?.price_range"
        class="mb-4 text-body font-medium text-grey-900 md:mb-0"
        itemprop="price"
      >
        {{ service.content?.price_range }}
      </p>

      <!-- Tags -->
      <p v-if="service.tags?.length" class="text-body text-grey-500">
        {{ $t("vendor_page.tags") }}
      </p>
      <div v-if="service.tags?.length" class="mb-4 md:mb-0">
        <div
          v-if="service.tags.length <= 3"
          class="flex flex-wrap items-start gap-1"
        >
          <BaseTag
            v-for="(tag, index) in service.tags"
            :key="index"
            :tag="tag"
            small
          />
        </div>
        <div v-else class="flex flex-wrap items-start gap-1">
          <BaseTag
            v-for="(tag, index) in service.tags.slice(0, 2)"
            :key="index"
            :tag="tag"
            small
          />
          <BaseTooltip :text="service.tags.slice(2, 999).join(', ')">
            <BaseTag
              :tag="`+${service.tags.slice(2, 999).length} tags`"
              small
              class="block"
            />
          </BaseTooltip>
        </div>
      </div>

      <!-- Minimum order -->
      <p v-if="service.content?.minimum_pax" class="text-body text-grey-500">
        {{ $t("vendor_page.minimum_order") }}
      </p>
      <p
        v-if="service.content?.minimum_pax"
        class="mb-4 text-body font-medium text-grey-900 md:mb-0"
      >
        {{ service.content.minimum_pax }}
      </p>

      <!-- Smiley report -->
      <p
        v-if="service.content?.smiley && service.content?.smiley_link"
        class="text-body text-grey-500"
      >
        {{ $t("vendor_page.smiley_report") }}
      </p>
      <div
        v-if="service.content?.smiley && service.content?.smiley_link"
        class="mb-4 flex items-center gap-2 md:mb-0"
      >
        <component
          :is="SMILEY_MAP[service.content.smiley]"
          class="h-[24px] w-[24px]"
        />
        <NuxtLink
          :href="service.content.smiley_link"
          class="text-body font-medium text-brand-600"
          target="_blank"
        >
          {{ $t("vendor_page.view") }}
          <BaseIcon icon="link-external" :size="14" />
        </NuxtLink>
      </div>

      <!-- Organic badge -->
      <p v-if="hasOrganic" class="text-body text-grey-500">
        {{ $t("vendor_page.organic_badge") }}
      </p>
      <div
        v-if="service.content.organic && hasOrganic"
        class="mb-4 flex items-center gap-1 text-body md:mb-0"
        :style="`color: ${ORGANIC_MAP[service.content.organic].color}`"
      >
        <img
          :src="ORGANIC_MAP[service.content.organic].image"
          loading="lazy"
          class="h-[24px]"
          alt=""
        />
        {{ ORGANIC_MAP[service.content.organic].text }}
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import SmileyBad from "~/assets/smiley-bad.svg?component";
import SmileyNeutral from "~/assets/smiley-neutral.svg?component";
import SmileyGood from "~/assets/smiley-good.svg?component";
import OrganicBronze from "~/assets/organic-bronze.png";
import OrganicSilver from "~/assets/organic-silver.png";
import OrganicGold from "~/assets/organic-gold.png";

const { t } = useI18n();

const props = defineProps<{
  service: OgApi.VendorFullService;
}>();

const SMILEY_MAP: { [key: string]: any } = {
  bad: SmileyBad,
  neutral: SmileyNeutral,
  good: SmileyGood,
};

const ORGANIC_MAP: {
  [key: string]: { image: any; text: string; color: string };
} = {
  bronze: {
    image: OrganicBronze,
    text: t("vendor_page.organic_bronze"),
    color: "#C36F42",
  },
  silver: {
    image: OrganicSilver,
    text: t("vendor_page.organic_silver"),
    color: "#9C9BA1",
  },
  gold: {
    image: OrganicGold,
    text: t("vendor_page.organic_gold"),
    color: "#CBB15B",
  },
};

const hasOrganic = computed(() => {
  if (Array.isArray(props.service.content)) return false;

  return (
    typeof props.service.content?.organic === "string" &&
    props.service.content?.organic !== "none"
  );
});
</script>
