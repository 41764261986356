<template>
  <div :class="{ sticky: sticky }">
    <ContactForm
      :on-submit="submit"
      class="mb-9"
      :class="loadingClasses"
      :submitted-successfully="submittedSuccessfully"
      :errors="formErrors"
      :create-account-link="createAccountLink"
    />
    <BaseTooltip
      :text="$t('service_page.officeguru_protection_description')"
      anchor-top
      full-width
    >
      <div
        class="flex items-center justify-center gap-2 text-center text-body font-medium text-grey-700"
      >
        <BaseIcon icon="protection" />{{
          $t("service_page.officeguru_protection")
        }}
      </div>
    </BaseTooltip>
  </div>
</template>

<script lang="ts" setup>
import { submitForm } from "~/utils/form";
const props = defineProps({
  service: {
    type: Object as PropType<Service>,
    default: null,
  },
  vendor: {
    type: Object,
    default: null,
  },
  sticky: {
    type: Boolean,
    default: false,
  },
  createAccountLink: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["submitted"]);
const submittedSuccessfully = ref(false);
const loading = ref(false);
const formErrors = ref(null);
const loadingClasses = computed(() => {
  return loading.value ? "opacity-50 pointer-events-none" : "";
});
function submit(data: object) {
  loading.value = true;
  formErrors.value = null;
  if (props.service) {
    submitForm("service_contact", {
      ...data,
      url: window.location.href,
      service_id: props.service.id,
      service_name: props.service.title,
      lang: globalThis.lang,
    })
      .then(() => {
        emit("submitted");
        submittedSuccessfully.value = true;
      })
      .catch((err) => {
        // err.data.error is an object of errors from Statamic
        formErrors.value = err?.data?.error || true;
      })
      .finally(() => {
        loading.value = false;
      });
  } else if (props.vendor) {
    submitForm("vendor_contact", {
      ...data,
      url: window.location.href,
      vendor_id: props.vendor.id,
      vendor_name: props.vendor.name,
      lang: globalThis.lang,
    })
      .then(() => {
        emit("submitted");
        submittedSuccessfully.value = true;
      })
      .catch((err) => {
        // err.data.error is an object of errors from Statamic
        formErrors.value = err?.data?.error || true;
      })
      .finally(() => {
        loading.value = false;
      });
  }
}
</script>
