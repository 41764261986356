<template>
  <BasePopover
    no-padding
    hide-on-click-inside
    content-max-height="250px"
    v-bind="popover ? popover : {}"
  >
    <template #trigger>
      <div
        class="typo-body flex h-full select-none items-center justify-between text-ellipsis rounded-lg border border-grey-300 px-2 py-1 text-body text-grey-700 transition-all hover:bg-grey-50"
      >
        <span
          class="truncate whitespace-nowrap"
          :title="currentlySelectedLabel()"
        >
          {{ currentlySelectedLabel() }}
        </span>
        <BaseIcon
          icon="caret-down"
          :size="12"
          class="ml-3 text-grey-500 transition-all"
        />
      </div>
    </template>

    <template #content>
      <div
        class="typo-body flex max-w-[100vw] flex-col gap-1 overflow-auto rounded-lg border border-grey-300 bg-white p-2 text-grey-900"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          class="flex w-full cursor-pointer items-center justify-between rounded-lg p-2 hover:bg-grey-100"
          :class="{
            'bg-grey-100 font-medium': optionIsSelected(option.value),
          }"
          @click="toggleOption(option.value)"
        >
          <div :class="{ 'ml-4': option.isChild }" class="whitespace-nowrap">
            {{ option.label }}
          </div>
          <div class="ml-2 w-[14px]">
            <BaseIcon
              v-if="optionIsSelected(option.value)"
              icon="checkmark"
              class="text-brand-600"
              :size="14"
            />
          </div>
        </div>
      </div>
    </template>
  </BasePopover>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { BasePopoverProps } from "~/types/component-props";
const { t } = useI18n();

interface Props {
  options: { label: string; value: string | number; isChild?: boolean }[];
  modelValue: string | number | null;
  placeholder?: string;
  allowDeselect?: boolean;
  selectedTranslationKey?: string;
  popover?: BasePopoverProps;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: "",
  allowDeselect: false,
  selectedTranslationKey: undefined,
  isChild: false,
  popover: () => ({}),
});

const emit = defineEmits(["update:modelValue"]);

const valueModel = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value);
  },
});

function currentlySelectedLabel() {
  const option = props.options.find(
    (option) => option.value === valueModel.value,
  );
  if (option) {
    return props.selectedTranslationKey
      ? t(props.selectedTranslationKey, { label: option.label })
      : option.label;
  }
  return props.placeholder;
}

function optionIsSelected(value: string | number) {
  return valueModel.value === value;
}

function toggleOption(value: string | number) {
  if (props.allowDeselect && optionIsSelected(value)) {
    valueModel.value = null;
    return;
  }
  valueModel.value = value;
}
</script>
