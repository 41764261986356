<template>
  <span class="inline-block">
    <component :is="iconComponent" :style="style" />
  </span>
</template>

<script setup lang="ts">
import icons from "./icon-component-map";

interface Props {
  icon: BaseIconName;
  size?: number;
  color?: string;
}
// You can check the README.md file, to see how to add new icons
const props = withDefaults(defineProps<Props>(), {
  color: "currentColor",
  size: 20,
});

// @ts-ignore
const iconComponent = computed(() => icons?.[props.icon]?.render() || null);

const style = computed(() => {
  return {
    color: props.color,
    height: `${props.size}px`,
    width: `${props.size}px`,
  };
});
</script>
