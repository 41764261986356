<template>
  <span
    class="typo-body inline-flex items-center gap-1 rounded-xl border border-grey-100 bg-semantic-info-50 px-2 text-semantic-info-700"
    ><BaseIcon
      v-if="displayIcon"
      :size="16"
      :icon="displayIcon as BaseIconName"
    />
    <slot>{{ label }}</slot></span
  >
</template>

<script lang="ts" setup>
const props = defineProps<{ label?: string }>();

const labelIconMap: { [key: string]: BaseIconName } = {
  "top-vendor": "trophy",
  "in-high-demand": "star-circle",
};

const displayIcon: ComputedRef<BaseIconName | boolean> = computed(() => {
  if (!props.label) return false;
  return labelIconMap[props.label] ? labelIconMap[props.label] : false;
});
</script>
