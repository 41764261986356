export function useVerticalPadding(props: any) {
  return computed(() => {
    interface PaddingSizes {
      [key: string]: string;
    }

    const paddingSizes: PaddingSizes = {
      small: "py-4",
      medium: "py-8",
      large: "py-16",
      "x-large": "py-24",
      "xx-large": "py-48",
      "xxx-large": "py-72",
    };
    return paddingSizes[props.content.vertical_padding.value];
  });
}
