<template>
  <BaseButton
    class="whitespace-nowrap"
    type="clear"
    color="black"
    :size="size"
    blank
    :href="href as string"
  >
    {{ $t("components.login") }}
  </BaseButton>
</template>

<script setup lang="ts">
const $config = useRuntimeConfig();
const href = $config.public.customerAppUrl;

withDefaults(
  defineProps<{
    size: "small" | "medium" | "large" | "x-large";
  }>(),
  {
    size: "medium",
  },
);
</script>
