<template>
  <span>{{ dayList }}</span>
</template>

<script lang="ts" setup>
const { t: $t } = useI18n();

interface Props {
  answer: [];
}

const props = defineProps<Props>();

const dayList = computed(() => {
  const days = [
    undefined,
    $t("request_flow.days.monday"),
    $t("request_flow.days.tuesday"),
    $t("request_flow.days.wednesday"),
    $t("request_flow.days.thursday"),
    $t("request_flow.days.friday"),
    $t("request_flow.days.saturday"),
    $t("request_flow.days.sunday"),
  ];

  return props.answer.map((day: number) => days[day]).join(", ");
});
</script>
