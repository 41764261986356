<template>
  <div v-if="videoReady && videoPath">
    <div class="relative h-full overflow-hidden">
      <iframe
        :src="videoPath"
        loading="lazy"
        class="absolute left-0 top-0 h-full w-full border-0"
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        allowfullscreen="true"
      ></iframe>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  vendor: OgApi.VendorFull;
}
const props = defineProps<Props>();
const videoReady = computed(() => props.vendor?.video?.info?.ready_to_stream);

const videoPath = computed(() => {
  const videoBasePath = props.vendor?.video?.path
    ? `https://customer-iigwdqeduomqs9d5.cloudflarestream.com/${props.vendor.video.path}/iframe`
    : undefined;

  const videoThumbnailQuery = props.vendor?.video?.info?.thumbnail
    ? encodeURIComponent(`?poster=${props.vendor.video?.info?.thumbnail}`)
    : "";

  if (videoBasePath) {
    return `${videoBasePath}${videoThumbnailQuery}`;
  }
  return undefined;
});
</script>
