<template>
  <img
    :srcset="srcset"
    :src="image"
    :alt="alt || ''"
    :width="width"
    :height="height"
    :loading="loading"
    :fetchpriority="fetchPriority"
  />
</template>

<script lang="ts" setup>
import { useCmsImage } from "~/utils/use-cms-image";

interface Props {
  src: string;
  alt?: string | null;
  maxWidth: number;
  loading?: "lazy" | "eager" | "auto";
  fetchPriority?: "high" | "low" | "auto";
  // width and height for browser to determine intrinsic size before image is loaded
  width?: number;
  height?: number;
  // width and height are marked as optional here, but validated down below - if something NEEDS to use
  // CmsImage without dimensions, it can pass allowEmptyDimensions prop. Please only use this if you know
  // that a layout shift won't occur.
  allowEmptyDimensions?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  loading: "lazy",
  fetchPriority: "auto",
  alt: "",
  allowEmptyDimensions: false,
  width: undefined,
  height: undefined,
});

const image = useCmsImage({
  src: props.src,
  params: `width=${props.maxWidth}`,
});

const srcset = computed(() => {
  if (props.src.endsWith(".svg")) {
    return;
  }

  return `
  ${
    useCmsImage({
      src: props.src,
      params: `width=${props.maxWidth}`,
    }).value
  } 1x,
  ${
    useCmsImage({
      src: props.src,
      params: `width=${props.maxWidth}&dpr=2`,
    }).value
  } 2x,
  ${
    useCmsImage({
      src: props.src,
      params: `width=${props.maxWidth}&dpr=3`,
    }).value
  } 3x`;
});

onMounted(() => {
  validate();
});

function validate() {
  const lowSeverityErrors: { name: string; message: string }[] = [];
  const highSeverityErrors: { name: string; message: string }[] = [];

  if (!props.allowEmptyDimensions) {
    if (typeof props.width !== "number") {
      highSeverityErrors.push({
        name: "Missing width",
        message: "Image is missing width!",
      });
    }
    if (typeof props.height !== "number") {
      highSeverityErrors.push({
        name: "Missing height",
        message: "Image is missing height!",
      });
    }
  }
  if (!props.src) {
    highSeverityErrors.push({
      name: "Missing src",
      message: "Image is missing src!",
    });
  }
  if (!props.maxWidth) {
    highSeverityErrors.push({
      name: "Missing max-width",
      message: "Image is missing max-width!",
    });
  }
  if (typeof props.alt !== "string") {
    lowSeverityErrors.push({
      name: "Missing alt value",
      message: `Image with src ${props.src} is missing alt value!`,
    });
  }
  if (!/images(-dev)?\.officeguru/.test(props.src)) {
    highSeverityErrors.push({
      // eslint-disable-next-line
      name: "Missing proper host: /images(-dev)?\.officeguru/",
      message: `Image with src ${props.src} is not shipped via our image proxy!`,
    });
  }
  if (lowSeverityErrors.length) {
    useBugsnag().notify(
      new Error(
        `Low severity CmsImage error(s) occurred: ${lowSeverityErrors
          .map((e) => `"${e.name}"`)
          .join(", ")}.`,
      ),
      (event) => {
        event.addMetadata("additional", {
          lowSeverityErrors,
        });
      },
    );
  }
  if (highSeverityErrors.length) {
    useBugsnag().notify(
      new Error(
        `High severity CmsImage error(s) occurred: ${highSeverityErrors
          .map((e) => `"${e.name}"`)
          .join(", ")}.`,
      ),
      (event) => {
        event.addMetadata("additional", {
          highSeverityErrors,
        });
      },
    );
  }
}
</script>
