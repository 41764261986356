<template>
  <div class="space-y-5">
    <div
      class="group relative flex cursor-pointer items-start gap-2"
      @click="emit('update:value', !value)"
    >
      <div class="flex h-6 select-none items-center">
        <div
          class="flex h-6 w-6 items-center justify-center rounded-md border transition duration-300 ease-linear"
          :class="
            value
              ? 'bg-brand-600 border-0'
              : 'border-grey-300 group-hover:border-grey-500'
          "
        >
          <BaseIcon
            v-if="value"
            icon="checkmark"
            :size="13"
            class="text-white"
          />
        </div>
        <input
          :id="inputId"
          :value="value"
          aria-describedby="comments-description"
          name="comments"
          type="checkbox"
          class="invisible h-0 w-0"
          @change="
            emit('update:value', ($event.target as HTMLInputElement).checked)
          "
        />
      </div>
      <div class="leading-6">
        <label
          :for="inputId"
          class="cursor-pointer text-body text-grey-700"
          v-html="label"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { randomId } from "~/utils/random-id";
const emit = defineEmits(["update:value"]);
defineProps({
  value: {
    type: Boolean,
  },
  label: {
    type: String,
    required: true,
  },
});
const inputId = ref(randomId());
</script>
