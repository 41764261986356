import { computed } from "vue";

type Alignment = "left" | "center" | "right";

function useHorizontalAlignment(props: any) {
  return computed(() => {
    const map: { left: string; center: string; right: string } = {
      left: "items-start",
      center: "items-center",
      right: "items-end",
    };

    const alignment = props.content.horizontal_alignment?.value as
      | Alignment
      | undefined;

    // backwards compatibility
    return !alignment ? map.left : map[alignment];
  });
}

export { useHorizontalAlignment };
