<template>
  <component :is="component">
    <iframe
      class="w-full"
      :class="verticalPadding"
      :src="formLink"
      :style="{
        height: `${height}px`,
      }"
    />
  </component>
</template>

<script setup lang="ts">
import { useVerticalPadding } from "~/components/cms-blocks/components/use-vertical-padding";
import { BaseContent } from "#components";

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
  wrapInBaseContent: {
    type: Boolean,
    default: true,
  },
});

const formLink = computed(() => {
  return props.content.form_link;
});

const height = computed(() => {
  return props.content.height;
});

const component = computed(() => {
  return props.wrapInBaseContent ? BaseContent : "div";
});

const verticalPadding = useVerticalPadding(props);
</script>
