<template>
  <div class="contact-form-card rounded-xl p-6">
    <form v-if="!submittedSuccessfully" class="flex flex-col">
      <h5 class="text-h5-lg font-bold">
        {{ $t("service_page.contact_me_about_offers_today") }}
      </h5>
      <p class="text-body text-grey-700">
        {{ $t("service_page.you_will_be_contacted_by_specialists") }}
      </p>
      <input
        v-model="verification"
        type="checkbox"
        placeholder="verification"
        class="invisible"
      />
      <input
        v-model="fullName"
        type="text"
        :placeholder="$t('service_page.full_name')"
        class="mb-3 w-full rounded-lg bg-grey-100 px-3 py-2 text-body text-grey-500 focus:outline-none"
      />
      <input
        v-model="company"
        type="text"
        :placeholder="$t('service_page.company')"
        class="mb-3 w-full rounded-lg bg-grey-100 px-3 py-2 text-body text-grey-500 focus:outline-none"
      />
      <input
        v-model="phoneNumber"
        type="tel"
        :placeholder="$t('service_page.phone_number')"
        class="mb-3 w-full rounded-lg bg-grey-100 px-3 py-2 text-body text-grey-500 focus:outline-none"
      />
      <input
        v-model="email"
        type="email"
        :placeholder="$t('service_page.email')"
        class="mb-3 w-full rounded-lg px-3 py-2 text-body text-grey-500 focus:outline-none"
        :class="
          typeof errors === 'object' && errors?.email
            ? 'border border-semantic-negative-700 bg-semantic-negative-50'
            : 'bg-grey-100'
        "
      />
      <span
        v-if="typeof errors === 'object' && errors?.email"
        class="-mt-1.5 mb-3 text-caption text-semantic-negative-700"
        ><BaseIcon class="-mb-0.5 mr-1" icon="close" :size="12" color="red" />
        {{ $t("service_page.valid_email") }}
      </span>
      <div class="mb-6 mt-1 flex items-start gap-4">
        <ContactFormCheckbox
          v-model:value="terms"
          :label="$t('service_page.i_accept_officeguru_stores_information')"
        />
      </div>
      <!-- generic error will only be shown if errors is true, not if is a complex error object -->
      <BaseButton
        class="w-full"
        :disabled="submitDisabled"
        :class="errors === true ? 'mb-1' : 'mb-4'"
        @click="submit"
      >
        {{ $t("service_page.contact_me_today") }}
      </BaseButton>
      <div v-if="errors === true" class="mb-1 flex items-center gap-1">
        <BaseIcon icon="close" :size="12" color="red" />
        <p class="text-caption text-semantic-negative-700">
          {{ $t("service_page.something_went_wrong") }}
        </p>
      </div>
      <div class="or-line mb-1 text-caption text-grey-500">
        {{ $t("service_page.or") }}
      </div>
      <p class="text-center text-body text-grey-500">
        {{ $t("service_page.want_to_get_started_by_yourself") }}
        <NuxtLink
          :to="createAccountLink"
          class="text-body text-semantic-info-700"
          @click="onCreateAccountClick"
          >{{ $t("service_page.create_account") }}</NuxtLink
        >
      </p>
    </form>

    <div v-else class="flex flex-col gap-4">
      <div class="flex gap-4 text-semantic-positive-500">
        <BaseIcon icon="checkmark-stamp" :size="30" />
        <div class="flex flex-col">
          <h5 class="text-body-lg font-bold">
            1. {{ $t("service_page.we_have_received_your_request") }}
          </h5>
        </div>
      </div>
      <BaseIcon icon="arrow-down-long" class="text-grey-200" :size="24" />
      <div class="flex gap-4 text-grey-500">
        <BaseIcon icon="checkmark-stamp" class="text-grey-300" :size="30" />
        <div class="flex flex-col">
          <h5 class="text-body-lg font-bold">
            2. {{ $t("service_page.we_will_contact_you_in_1_2_days") }}
          </h5>
          <p class="text-body">
            {{
              $t("service_page.a_quick_chat_to_hear_more_about_your_company")
            }}
          </p>
        </div>
      </div>
      <BaseIcon icon="arrow-down-long" class="text-grey-200" :size="24" />
      <div class="flex gap-4 text-grey-500">
        <BaseIcon icon="checkmark-stamp" class="text-grey-300" :size="30" />
        <div class="flex flex-col">
          <h5 class="text-body-lg font-bold">
            3. {{ $t("service_page.review_your_offer") }}
          </h5>
          <p class="text-body">
            {{ $t("service_page.the_vendor_will_send_you_an_offer") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUIStore } from "~/store/UIStore";

const uiStore = useUIStore();
const props = defineProps<{
  onSubmit: Function;
  submittedSuccessfully: boolean;
  errors: boolean | null | { email: string };
  createAccountLink: string;
}>();

const submitDisabled = computed(() => {
  return (
    !terms.value ||
    !fullName.value ||
    !company.value ||
    !phoneNumber.value ||
    !email.value
  );
});

const fullName = ref("");
const company = ref("");
const phoneNumber = ref("");
const email = ref("");
const terms = ref(false);
const verification = ref(null);
// verification field is bound to honeyPot for statamic forms
function submit() {
  props.onSubmit({
    full_name: fullName.value,
    company: company.value,
    phone: phoneNumber.value,
    email: email.value,
    verification: verification.value,
    terms: terms.value,
  });
}

function onCreateAccountClick() {
  uiStore.enableScroll();
  uiStore.showMainNav();
}
</script>

<style scoped>
.or-line {
  display: flex;
  align-items: center;
  gap: 8px;
}
.or-line::before,
.or-line::after {
  background-color: #e4e6eb;
  content: "";
  display: block;
  height: 1px;
  flex: 1;
}

.contact-form-card {
  box-shadow:
    22px 109px 44px 0px #00000003,
    12px 61px 37px 0px #00000005,
    5px 27px 28px 0px #00000012,
    1px 7px 15px 0px #00000014,
    0px 0px 3px 0px #05051f1c;
}
</style>
