<template>
  <div
    v-if="serviceFilter || ratingFilter"
    class="slide-down mb-8 flex flex-col items-start gap-1 text-grey-500 md:flex-row md:items-center"
  >
    {{ $t("vendor_page.filters") }}:
    <BaseButton
      v-if="serviceFilter"
      type="outline"
      size="small"
      color="black"
      class="slide-down !rounded-full !border-grey-300 py-2 leading-none transition-all hover:bg-grey-50"
      @click="resetServiceFilter"
    >
      {{ serviceMap[serviceFilter].name }}
      <BaseIcon icon="close" :size="12" />
    </BaseButton>
    <BaseButton
      v-if="ratingFilter"
      type="outline"
      size="small"
      color="black"
      class="slide-down !rounded-full !border-grey-300 py-2 leading-none transition-all hover:bg-grey-50"
      @click="resetRatingFilter"
    >
      {{ $t("vendor_page.star_rating_x", { rating: ratingFilter }) }}
      <BaseIcon icon="close" :size="12" />
    </BaseButton>
  </div>
</template>

<script lang="ts" setup>
interface ServiceMap {
  [key: string]: OgApi.Service;
}
interface Props {
  serviceFilter: string | null;
  ratingFilter: number | null;
  serviceMap: ServiceMap;
}

defineProps<Props>();

const emit = defineEmits(["resetServiceFilter", "resetRatingFilter"]);
function resetServiceFilter() {
  emit("resetServiceFilter", null);
}

function resetRatingFilter() {
  emit("resetRatingFilter", null);
}
</script>

<style scoped>
.slide-down {
  animation: slide-down 0.3s ease-out;
}

@keyframes slide-down {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
