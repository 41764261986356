<template>
  <BaseContent>
    <ViewAllHeader
      :header-text="headerText"
      :header-link="headerLink"
      :header-link-text="headerLinkText"
    />
    <div
      class="grid gap-8"
      :style="`grid-template-columns: repeat(${links.length}, minmax(0, 1fr));`"
    >
      <NuxtLink v-for="(link, index) in links" :key="index" :to="link.link">
        <div
          class="h-[100px] bg-cover bg-center"
          :style="`background: url('${getBackgroundImageUrl(link)}')`"
        />
        <p class="typo-body mb-0.5 mt-4 font-bold">
          {{ link.title }}
        </p>
        <p class="typo-body text-grey-700">{{ link.subtitle }}</p>
      </NuxtLink>
    </div>
  </BaseContent>
</template>

<script setup>
import { useCmsImage } from "~/utils/use-cms-image";

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  raw: {
    type: Object,
    required: true,
  },
});

const headerText = computed(() => props.content.header_text);
const links = computed(() => props.content.links);
const headerLink = computed(() => {
  if (
    !(Array.isArray(props.content.header_link) && props.content.header_link[0])
  )
    return false;
  return props.content.header_link[0].link;
});
const headerLinkText = computed(() => {
  if (
    !(Array.isArray(props.content.header_link) && props.content.header_link[0])
  )
    return false;
  return props.content.header_link[0].text;
});

function getBackgroundImageUrl(link) {
  return useCmsImage({ src: link?.image?.url, params: "width=215" }).value;
}
</script>
