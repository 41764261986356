<template>
  <figure
    class="pointer-events-none absolute left-0 top-0 flex h-full w-full translate-x-[0%] flex-col items-center justify-center"
    :class="{ 'opacity-0': hidden }"
    :aria-hidden="hidden"
  >
    <p
      :key="!hidden ? index : `${index}-hidden`"
      class="text-white"
      :class="{ 'opacity-0': hidden, 'fade-in': !hidden }"
    >
      {{ index + 1 }} / {{ images.length }}
    </p>
    <!-- we have no idea about image dimensions here, can not prevent layout shifts -->
    <CmsImage
      :key="images[index].url"
      :src="images[index].url"
      :alt="altString"
      :max-width="2048"
      allow-empty-dimensions
      class="my-4 max-h-[80%] max-w-[80%]"
    />
    <figcaption
      :key="!hidden ? index : `${index}-hidden`"
      class="text-center text-white"
      :class="{ 'opacity-0': hidden, 'fade-in': !hidden }"
    >
      {{ images[index].description }}
    </figcaption>
  </figure>
</template>

<script lang="ts" setup>
const props = defineProps<{
  images: Array<{ description: string; url: string }>;
  index: number;
  hidden?: boolean;
  altPrefix?: string;
}>();

const altString = computed(() => {
  if (props.altPrefix)
    return `${props.altPrefix} - ${props.images[props.index].description}`;
  return props.images[props.index].description;
});
</script>

<style scoped>
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.fade-in {
  animation: fade-in 0.2s ease-in-out forwards;
}
</style>
