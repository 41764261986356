<template>
  <BaseContent
    :style="`background-color: ${
      props.content?.background_color || 'transparent'
    }`"
  >
    <div :class="verticalPadding">
      <h2
        v-if="props.content?.header_text"
        class="typo-h2 mb-3 md:mb-4"
        :class="{ 'text-center': props.content?.alignment.value === 'center' }"
      >
        {{ props.content.header_text }}
      </h2>
      <div
        v-if="props.content?.description"
        class="typo-body-xl mb-8 text-grey-700"
        :class="{ 'text-center': props.content?.alignment.value === 'center' }"
      >
        {{ props.content.description }}
      </div>
      <!-- done -->
      <CmsImage
        :src="props.content.image.url"
        :alt="props.content.image.alt"
        :width="props.content.image.width"
        :height="props.content.image.height"
        :max-width="1200"
        :class="{ [imageRounded]: !!imageRounded }"
        class="w-full"
      />
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
import { useImageRounded } from "~/components/cms-blocks/components/use-image-rounded";
import { useVerticalPadding } from "~/components/cms-blocks/components/use-vertical-padding";

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
});

const imageRounded = useImageRounded(props);
const verticalPadding = useVerticalPadding(props);
</script>
