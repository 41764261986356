<template>
  <BaseContent
    class="fixed z-50 border-b border-grey-100 bg-white"
    content-classes="!py-4"
    tag="header"
  >
    <div class="flex justify-between">
      <div class="flex items-center">
        <GlobalSearch compact />
      </div>
      <div class="ml-4 flex flex-row items-center gap-4">
        <template v-if="visibleNavigationItems">
          <MainMenu
            :items="visibleNavigationItems as NavigationItem[]"
            class="hidden md:flex"
          />
          <div class="hidden select-none text-grey-200 md:block">|</div>
        </template>
        <PageHeaderCartLink />
      </div>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
import { PageHeaderProps } from "~/types/component-props";
import GlobalSearch from "~/components/global-search.vue";

const props = defineProps<PageHeaderProps>();

const visibleNavigationItems = computed(() => {
  return props.navigationItems.filter((item) => item.page.show_when_embedded);
});
</script>
