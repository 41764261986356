<template>
  <div class="flex flex-col items-center">
    <div
      class="w-full bg-white"
      :class="{
        'rounded-xl p-8 pb-5 shadow-alternative-soft-glow': !usedInFlow,
      }"
    >
      <form v-if="usedInFlow || !success">
        <h2 v-if="!usedInFlow" class="typo-h3 mb-2">
          {{ $t("customer_signup_form.sign_up_it_is_free") }}
        </h2>
        <span
          v-if="!usedInFlow"
          class="typo-body-xl mb-5 block text-grey-700"
          >{{ $t("customer_signup_form.start_getting_control") }}</span
        >
        <FormsComponentsFieldLabel
          class="mb-3"
          :label="$t('customer_signup_form.label.full_name')"
          field-name="customer.person"
          :errors="errors"
          required
        >
          <FormsComponentsInputText
            v-model:value="form.customer.person"
            :placeholder="$t('customer_signup_form.placeholder.full_name')"
            error-name-space="customer_signup_form"
            field-name="customer.person"
            :errors="errors"
          />
        </FormsComponentsFieldLabel>

        <FormsComponentsFieldLabel
          class="mb-3"
          :label="$t('customer_signup_form.label.company')"
          field-name="customer.name"
          :errors="errors"
          required
        >
          <FormsComponentsInputText
            v-model:value="form.customer.name"
            :placeholder="$t('customer_signup_form.placeholder.company')"
            error-name-space="customer_signup_form"
            field-name="customer.name"
            :errors="errors"
          />
        </FormsComponentsFieldLabel>

        <FormsComponentsFieldLabel
          class="mb-3"
          :label="$t('customer_signup_form.label.phone')"
          field-name="user.phone"
          :errors="errors"
        >
          <FormsComponentsInputText
            v-model:value="form.user.phone"
            :placeholder="$t('customer_signup_form.placeholder.phone')"
            error-name-space="customer_signup_form"
            field-name="user.phone"
            :errors="errors"
          />
        </FormsComponentsFieldLabel>

        <div class="mb-3 flex gap-4">
          <FormsComponentsFieldLabel
            class="w-full grow"
            :label="$t('customer_signup_form.label.address')"
            field-name="customer.address"
            :errors="errors"
            required
          >
            <FormsComponentsInputText
              v-model:value="form.customer.address"
              :placeholder="$t('customer_signup_form.placeholder.address')"
              error-name-space="customer_signup_form"
              field-name="customer.address"
              :errors="errors"
            />
          </FormsComponentsFieldLabel>
          <FormsComponentsFieldLabel
            :label="$t('customer_signup_form.label.zip')"
            field-name="customer.zip"
            :errors="errors"
            required
          >
            <FormsComponentsInputText
              v-model:value="form.customer.zip"
              :placeholder="$t('customer_signup_form.placeholder.zip')"
              error-name-space="customer_signup_form"
              field-name="customer.zip"
              :errors="errors"
            />
          </FormsComponentsFieldLabel>
        </div>

        <FormsComponentsFieldLabel
          class="mb-3"
          :label="$t('customer_signup_form.label.email')"
          field-name="user.email"
          :errors="errors"
          required
        >
          <FormsComponentsInputText
            v-model:value="form.customer.email"
            :placeholder="$t('customer_signup_form.placeholder.email')"
            error-name-space="customer_signup_form"
            field-name="user.email"
            :errors="errors"
          />
        </FormsComponentsFieldLabel>

        <FormsComponentsFieldLabel
          class="mb-3.5"
          :label="$t('customer_signup_form.label.password')"
          field-name="customer.password"
          :errors="errors"
          required
        >
          <FormsComponentsInputText
            v-model:value="form.user.password"
            :placeholder="$t('customer_signup_form.placeholder.password')"
            error-name-space="customer_signup_form"
            field-name="user.password"
            :errors="errors"
            password
          />
        </FormsComponentsFieldLabel>

        <FormsComponentsFieldLabel
          :label="termsAtLabel"
          field-name="customer.terms_at"
          :errors="errors"
          for-checkbox
          required
          class="items-center"
        >
          <FormsComponentsInputCheckbox
            v-model:value="form.customer.terms_at"
            field-name="customer.terms_at"
            error-name-space="customer_signup_form"
            :errors="errors"
            class="min-w-[12px]"
          />
        </FormsComponentsFieldLabel>

        <FormsComponentsFieldLabel
          class="-mt-1 items-center"
          :label="
            $t('customer_signup_form.label.marketing_content_and_newsletter')
          "
          field-name="user.gdpr"
          :errors="errors"
          for-checkbox
        >
          <FormsComponentsInputCheckbox
            v-model:value="form.user.gdpr"
            field-name="user.gdpr"
            error-name-space="customer_signup_form"
            :errors="errors"
            class="min-w-[12px]"
          />
        </FormsComponentsFieldLabel>

        <BaseButton
          :disabled="loading || isLoggedIn"
          class="mb-4 mt-7 w-full"
          @click="submit"
        >
          <template v-if="!loading && !isLoggedIn">
            {{ $t("customer_signup_form.button.submit") }}
          </template>
          <template v-else>
            {{ $t("customer_signup_form.creating_account") }}
            <BaseIcon color="white" icon="loading" class="ml-1 animate-spin" />
          </template>
        </BaseButton>

        <span
          v-if="genericError"
          class="typo-caption mt-3 flex max-w-[60ch] items-baseline text-semantic-negative-700"
        >
          <BaseIcon class="mr-1" icon="close" :size="12" color="red" />
          {{ $t("customer_signup_form.generic_error") }}
        </span>

        <span
          v-if="!usedInFlow"
          class="typo-body mt-5 block text-center font-medium"
          >{{ $t("customer_signup_form.already_have_account") }}
          <a
            class="text-brand-600"
            :href="config.public.customerAppUrl"
            target="_blank"
            rel="noopener"
            >{{ $t("customer_signup_form.log_in") }}</a
          ></span
        >
      </form>
      <div v-else>
        <FormsCustomerSignupFormSuccess />
      </div>
    </div>
    <FormsComponentsRecaptchaInfo class="mt-6 px-8" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { submitApiForm } from "~/utils/form";
import { useAuthStore } from "~/store/AuthStore";
import { track } from "~/utils/tracking/tracking";
const { isLoggedIn } = storeToRefs(useAuthStore());
const config = useRuntimeConfig();
const { t: $t } = useI18n();
const emit = defineEmits(["success"]);
const $router = useRouter();
const $posthog = usePosthog();

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  usedInFlow: {
    type: Boolean,
    default: false,
  },
});

const form = reactive({
  customer: {
    name: "", // company name
    person: "", // person name
    email: "",
    address: "",
    zip: "",
    terms_at: false, // if true, will be replaced with current ISO date (Y-m-d H:i:s) when making POST request
  },
  user: {
    email: "",
    gdpr: false,
    name: "",
    password: "",
    phone: "",
  },
});

// sync `customer.person => user.name` & `customer.email => user.email`
watch(
  [() => form.customer.person, () => form.customer.email],
  ([person, email]) => {
    form.user.name = person;
    form.user.email = email;
  },
);

const genericError = ref(false);
const errors = ref({});
const loading = ref(false);
const success = ref(false);

onMounted(() => {
  // logged in users should not be able to access the signup
  if (!props.usedInFlow && isLoggedIn.value) {
    $router.push("/");
  }
});

async function submit() {
  loading.value = true;

  try {
    const response: any = await submitApiForm("public/customers", {
      type: "minimum", // minimal validation
      ...form,
      customer: {
        ...form.customer,
        terms_at: form.customer.terms_at
          ? new Date().toISOString().replace("T", " ").split(".")[0]
          : "",
      },
      distinct_id: $posthog.id,
    });

    const data = response.data as OgApi.CustomerUserRegistrationResponse;

    // identify with posthog so we get the events tracked for the proper user
    $posthog.identify(form.user);

    setStatus(true, false, {});

    track({
      event: "sign_up",
      metadata: {
        method: "email",
      },
      posthogEvent: {
        name: "Customer sign up",
        properties: {
          url: window.location.href,
        },
      },
    });
    emit("success", data.owner.token);
  } catch (e: any) {
    if (e?.data?.errors) {
      setStatus(false, false, e.data.errors);
    } else {
      setStatus(false, true, {});
      useBugsnag().notify(e);
    }
  } finally {
    loading.value = false;
  }
}

function setStatus(
  successValue: boolean,
  genericErrorValue: boolean,
  errorsValue: any,
) {
  success.value = successValue;
  genericError.value = genericErrorValue;
  errors.value = errorsValue;
}

const termsAtLabel = computed(() => {
  return `${$t("customer_signup_form.label.i_have_read_and_accept")} <a href="${
    props.content?.terms_of_service?.url || "/"
  }" target="blank" rel="noopener">${$t(
    "customer_signup_form.label.terms_of_service",
  )}</a>`;
});
</script>
