<template>
  <router-link
    class="relative flex items-center justify-center"
    :to="{ name: 'cart' }"
  >
    <BaseIcon key="cart-1" icon="cart" :size="20" />
    <span
      v-if="cartStore.productsInCartCount"
      class="absolute right-0 top-0 inline-flex h-[22px] w-[22px] -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full border-[2px] border-white bg-brand-600 text-caption font-semibold text-white"
      >{{ cartStore.productsInCartCount }}</span
    >
  </router-link>
</template>

<script lang="ts" setup>
import { useCartStore } from "~/store/CartStore";
const cartStore = useCartStore();
</script>
