<template>
  <NuxtLink
    v-if="cmsValues?.link_customer_signup"
    :to="{
      name: cmsValues.link_customer_signup,
    }"
  >
    <BaseButton
      class="whitespace-nowrap"
      button-classes="!border-grey-300"
      type="outline"
      color="purple-brand"
      :size="size"
      blank
    >
      {{ $t("components.signup") }}
    </BaseButton>
  </NuxtLink>
</template>

<script setup lang="ts">
import { useCMSStore } from "~/store/CMSStore";

const { cmsValues } = useCMSStore();

withDefaults(
  defineProps<{
    size: "small" | "medium" | "large" | "x-large";
  }>(),
  {
    size: "medium",
  },
);
</script>
