<template>
  <div
    class="cursor-pointer rounded-xl border p-5"
    :class="{
      'pointer-events-none opacity-30': disabled,
      'border-[#8854FF]': isSelected,
      'group border-grey-200': !isSelected,
      '!cursor-default border-0 bg-grey-50': vendor.full_capacity,
    }"
    @click="onVendorBoxClick"
  >
    <div class="grid animate-redraw gap-3">
      <div class="flex">
        <BaseAvatar
          :image="{
            url: vendorLogo.url,
            alt: vendorLogo.alt,
          }"
          :fallback-text="vendor.name"
          class="mr-3"
        />
        <div class="flex flex-col justify-between py-0.5">
          <h3 class="typo-body font-semibold">{{ vendor.name }}</h3>
          <div
            v-if="vendor.rating.average > 0 && vendor.rating.count > 0"
            class="flex items-center gap-2"
          >
            <span class="typo-body text-grey-700">{{
              vendor.rating.average
            }}</span>
            <BaseIcon
              v-if="vendor.rating.average > 0"
              icon="star-full"
              :size="12"
            />
            <BaseIcon v-else icon="star-empty" :size="12" />
            <span class="typo-body text-grey-500"
              >({{ vendor.rating.count }})</span
            >
            <div v-if="minPax" class="flex items-center gap-2">
              <span class="text-grey-200">•</span>
              <BaseIcon
                icon="customer-app-persons_group"
                class="text-grey-700"
                :size="16"
              />
              <span class="typo-body text-grey-900">{{
                $t("request_flow.min_pax_count", { count: minPax })
              }}</span>
            </div>
          </div>
          <span v-else class="typo-body text-grey-500">{{
            $t("request_flow.new_on_officeguru")
          }}</span>
        </div>
        <div
          v-if="!vendor.full_capacity"
          class="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-md transition-colors duration-500 group-hover:border-brand-300 group-hover:bg-brand-50"
          :class="
            isSelected ? 'bg-brand-600' : 'bg-white border border-grey-300'
          "
        >
          <BaseIcon
            icon="checkmark"
            class="text-white group-hover:text-brand-600"
            :size="12"
          />
        </div>
      </div>
      <VendorBadges
        v-if="vendor.badges"
        :badges="vendor.badges"
        :full-capacity="vendor.full_capacity"
      />
      <div
        class="typo-body request-flow-vendor-description line-clamp-3"
        v-html="vendor.description"
      />
      <div v-if="vendor.slug && vendor.show_in_marketplace" class="mt-1 flex">
        <a
          :href="vendorProfilePath(vendor.slug)"
          target="_blank"
          data-disable-selection
        >
          <BaseButton
            data-disable-selection
            type="outline"
            color="black"
            size="small"
            class="border-grey-300"
            >{{ $t("request_flow.view_profile") }}</BaseButton
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t: $t } = useI18n();

interface Props {
  vendor: OgApi.VendorFull;
  isSelected: boolean;
  disabled: boolean;
}
const props = defineProps<Props>();
const emit = defineEmits(["toggleSelection"]);

function onVendorBoxClick(event: Event) {
  // don't allow selection if capacity is full
  if (props.vendor.full_capacity) {
    return;
  }
  // make sure to not toggle selection when element is clicked that has an own click action, e.g. "Vis profil" button
  if (
    event.target instanceof HTMLElement &&
    event.target.hasAttribute("data-disable-selection")
  ) {
    return;
  }
  emit("toggleSelection");
}

const vendorLogo = computed(() => {
  // vendor images on detail are strings while on list they are objects :-|
  if (typeof props.vendor.images?.logo === "object") {
    return {
      url: props.vendor.images?.logo?.url,
      alt: "",
    };
  }
  return {
    url: props.vendor.images?.logo,
    alt: "",
  };
});

const minPax = computed(() => {
  return props.vendor?.content?.minimum_pax;
});
</script>

<style>
.request-flow-vendor-description p {
  display: inline;
}
</style>
