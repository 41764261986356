<template>
  <div class="mb-8 min-w-0">
    <p class="mb-2 px-2 text-body font-semibold text-grey-500">
      {{ $t("vendor_page.rating_by_percentage") }}
    </p>

    <div class="flex w-[250px] max-w-full flex-col-reverse gap-1">
      <div
        v-for="(ratingVal, ratingKey) in calculatedStarRatings"
        :key="ratingKey"
        class="flex cursor-pointer items-center gap-2 rounded border-b border-transparent px-2 py-1 text-body text-grey-900 transition-all hover:scale-[1.02] hover:bg-grey-100"
        :class="{ 'bg-grey-100': ratingFilter === Number(ratingKey) }"
        @click="setRatingFilter(Number(ratingKey))"
      >
        <span class="min-w-[9px] text-center">
          {{ ratingKey }}
        </span>
        <BaseIcon icon="star-full" :size="10" />
        <BaseProgress :value="ratingVal" />
        <span class="min-w-[40px]">
          {{ `${ratingVal}%` }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  serviceFilter: string | null;
  ratingFilter: number | null;
  vendor: OgApi.VendorFull;
}

const emit = defineEmits(["setRatingFilter"]);

const props = defineProps<Props>();

const calculatedStarRatings = computed(() => {
  let reviews: VendorReview[] = [];
  if (props.serviceFilter) {
    reviews = props.vendor.reviews.filter(
      (review) => review.service_id === props.serviceFilter,
    );
  } else {
    reviews = props.vendor.reviews;
  }

  const ratingCount: Record<string, number> = {
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
    "5": 0,
  };

  Object.keys(ratingCount).forEach((key: string) => {
    const count = reviews.filter(
      (review) => review.rating === Number(key),
    ).length;
    ratingCount[key] = Math.round((count / reviews.length) * 100) || 0;
  });
  return ratingCount;
});

function setRatingFilter(value: number) {
  emit("setRatingFilter", value);
}
</script>
