<template>
  <span
    v-if="translatedErrors"
    class="typo-caption mt-1 flex items-baseline text-semantic-negative-700"
    ><BaseIcon class="mr-1" icon="close" :size="12" color="red" />
    {{ translatedErrors }}</span
  >
</template>

<script setup lang="ts">
import { useHasFieldError } from "~/utils/use-has-field-error";
const { t: $t } = useI18n();

const props = defineProps({
  errors: {
    type: Object,
    default: null,
  },
  fieldName: {
    type: String,
    required: true,
  },
  errorNameSpace: {
    type: String,
    required: true,
  },
});

const hasError = useHasFieldError(props);

const translatedErrors = computed(() => {
  if (!hasError.value) return null;

  return props.errors[props.fieldName]
    .map((error: { message: string; identifier: string }) => {
      const path = [
        props.errorNameSpace,
        props.fieldName,
        error.identifier,
      ].join(".");

      return $t(path);
    })
    .join(", ");
});
</script>
