<template>
  <div class="flex w-full">
    <NuxtLayout :name="layoutName" />
    <ClientOnly>
      <GlobalPageLoadingIndicator />
      <VitePluginChecker />
    </ClientOnly>
  </div>
</template>
<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";
import { useConsent } from "~/utils/use-consent";
const isEmbedded = useUIStore().isEmbedded;

const i18n = useI18n();
i18n.setLocale(globalThis.lang);
i18n.locale.value = globalThis.lang;

// trustpilot script
if (import.meta.client) {
  useHead({
    script: [
      {
        src: "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js",
        async: true,
        type: "text/javascript",
      },
    ],
  });
}
const uiStore = useUIStore();
watch(
  () => uiStore.scrollIsEnabled,
  (value) => {
    document.body.style.overflow = value ? "auto" : "hidden";
  },
);

onMounted(() => {
  const nuxtApp = useNuxtApp();
  useConsent();
  nuxtApp.$recaptcha.init();

  // Make sure the data is refreshed on initial preview load
  // (otherwise you would have to make a content change before seeing the most recent unsaved data)
  if (nuxtApp.$router.currentRoute.value.query.preview === "1") {
    globalThis.previewToken = nuxtApp.$router.currentRoute.value.query
      .token as string;
    refreshNuxtData();
  }

  // Listen for live preview updates from Statamic
  window.onmessage = function (e) {
    if (e.data.name === "statamic.preview.updated") {
      // Set the preview token again just in case it has changed
      globalThis.previewToken = e.data.token;
      refreshNuxtData();
    }
  };
});

const layoutName = computed(() => {
  if (isEmbedded) return "embedded";
  return undefined;
});
</script>
