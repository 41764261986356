<template>
  <div class="flex flex-col border-b border-grey-200 pb-10">
    <!-- Title and service filter -->
    <div class="mb-6 flex flex-col justify-between md:flex-row">
      <h3 class="text-body-lg font-semibold text-grey-900">
        {{ $t("vendor_page.latest_reviews") }}
      </h3>
      <SelectDropdown
        v-model="serviceFilter"
        :options="serviceFiltersList"
        allow-deselect
        :placeholder="$t('vendor_page.filter_by_service')"
        selected-translation-key="vendor_page.filtering_by_label"
      />
    </div>

    <SingleVendorReviewsRatingFilter
      :service-filter="serviceFilter"
      :rating-filter="ratingFilter"
      :vendor="vendor"
      @set-rating-filter="setRatingFilter"
    />

    <SingleVendorReviewsFilterBar
      :rating-filter="ratingFilter"
      :service-filter="serviceFilter"
      :service-map="serviceMap"
      @reset-rating-filter="resetRatingFilter"
      @reset-service-filter="resetServiceFilter"
    />

    <SingleVendorReviewsList
      :rating-filter="ratingFilter"
      :service-filter="serviceFilter"
      :service-map="serviceMap"
      :vendor="vendor"
    />
  </div>
</template>

<script lang="ts" setup>
import SingleVendorReviewsRatingFilter from "~/pages/components/vendor/single-vendor-reviews-rating-filter.vue";
import SingleVendorReviewsFilterBar from "~/pages/components/vendor/single-vendor-reviews-filter-bar.vue";
import SingleVendorReviewsList from "~/pages/components/vendor/single-vendor-reviews-list.vue";

interface Props {
  vendor: OgApi.VendorFull;
}
const props = defineProps<Props>();

interface ServiceMap {
  [key: string]: OgApi.Service;
}

const serviceMap = computed((): ServiceMap => {
  return props.vendor.services.reduce(
    (acc: any, service: OgApi.VendorFullService) => {
      acc[service.id as string] = service;
      return acc;
    },
    {},
  );
});

const ratingFilter = ref<number | null>(null);
function setRatingFilter(value: number) {
  ratingFilter.value = ratingFilter.value === value ? null : value;
}

const serviceFilter = ref<string | null>(null);
function resetServiceFilter() {
  serviceFilter.value = null;
}

function resetRatingFilter() {
  ratingFilter.value = null;
}

const serviceFiltersList = computed(() => {
  return Object.keys(serviceMap.value)
    .map((key) => {
      return {
        label: serviceMap.value[key].name,
        value: key,
      };
    })
    .filter((service) => {
      return props.vendor.reviews.some((review: { service_id: string }) => {
        return review.service_id === service.value;
      });
    })
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
});
</script>

<style scoped>
.slide-down {
  animation: slide-down 0.3s ease-out;
}

@keyframes slide-down {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
