<template>
  <BaseContent content-classes="!pb-0 !pt-0">
    <div class="border-b border-b-grey-200 pb-10">
      <h2 class="mb-4 text-h5-lg font-bold text-grey-900">
        {{ $t("service_page.others_also_bought") }}
      </h2>
      <div class="grid grid-cols-2 gap-6 md:grid-cols-4">
        <div
          v-for="related in relatedServices"
          :key="related.id"
          class="flex flex-col"
          itemscope
          itemtype="https://schema.org/Offer"
        >
          <NuxtLink :href="related.url" itemprop="url">
            <div
              class="mb-4 aspect-[3/2] rounded-lg bg-grey-200 bg-cover bg-center"
              :style="
                getServiceThumbnail(related)
                  ? `background-image: url('${getServiceThumbnail(related)}')`
                  : ''
              "
            />
            <h5
              class="mb-1 text-body font-semibold text-grey-900"
              itemprop="name"
            >
              {{ related.title }}
            </h5>
            <WysiwygRenderer
              :content="related.excerpt"
              class="!line-clamp-3 text-body text-grey-700"
            />
          </NuxtLink>
        </div>
      </div>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
import { useCmsImage } from "~/utils/use-cms-image";

const props = defineProps<{ service: Service }>();

const relatedServices = computed(() => {
  return props.service?.others_also_bought;
});

function getServiceThumbnail(service: Service) {
  if (typeof service.thumbnail?.url !== "string") return null;
  return useCmsImage({ src: service.thumbnail.url, params: "width=282" }).value;
}
</script>
