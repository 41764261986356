<template>
  <div
    class="absolute bottom-[-2px] mx-auto ml-[-6px] h-[2px] w-full max-w-[calc(100%-36px)] bg-grey-300 md:max-w-[594px]"
  >
    <div
      class="absolute h-[2px] bg-semantic-positive-500 transition-all duration-200"
      :style="progressStyles"
    ></div>
    <template v-for="(_, index) in slides" :key="index">
      <div
        v-if="isCurrent(index)"
        :style="`left: ${getSlideIndicator(index)}`"
        class="absolute mt-[-5px] flex h-[12px] w-[12px] rounded-full bg-[#AAF0DA] transition-[color] delay-100 duration-[0ms]"
      >
        <div
          class="m-auto h-[6px] w-[6px] rounded-full"
          :class="indicatorClasses(index)"
        />
      </div>
      <div
        v-else
        class="absolute mt-[-2px] h-[6px] w-[6px] rounded-full transition-[color] delay-100 duration-[0ms]"
        :class="indicatorClasses(index)"
        :style="`left: ${getSlideIndicator(index)}`"
      />
    </template>
  </div>
</template>
<script setup lang="ts">
interface Props {
  current: number;
  amountOfSlides: number;
}

const slides = computed(() => {
  return new Array(props.amountOfSlides).fill(null);
});

const props = withDefaults(defineProps<Props>(), {
  current: 1,
});

const progressStyles = computed(() => {
  if (typeof props.current !== "number") return;

  return `width: ${getSlideIndicator(props.current - 1)}`;
});

function indicatorClasses(index: number) {
  if (typeof props.current !== "number") return;

  return index < props.current ? "bg-semantic-positive-500" : "bg-grey-300";
}

function getSlideIndicator(index: number | null | undefined) {
  if (typeof index !== "number") return 1;

  return `${(index / (props.amountOfSlides - 1)) * 100}%`;
}

function isCurrent(index: number) {
  if (typeof props.current !== "number") return;

  return index === props.current - 1;
}
</script>
