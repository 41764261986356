<template>
  <div class="flex flex-col gap-1.5 md:flex-row">
    <button
      v-for="day in availableDays"
      :key="day.value"
      class="typo-body w-[78px] rounded-lg border border-grey-300 py-2 font-semibold text-grey-700 transition-colors"
      :class="{ 'border-brand-600 bg-[#F9F7FF]': isSelected(day.value) }"
      @click="toggleDay(day.value)"
    >
      <div class="flex items-center justify-center">
        {{ day.text }}
        <div
          class="ml-2 flex h-[14px] w-[14px] items-center justify-center rounded-full border border-grey-200 transition-colors"
          :class="{ 'bg-brand-600 text-white': isSelected(day.value) }"
        >
          <BaseIcon v-if="isSelected(day.value)" icon="checkmark" :size="8" />
        </div>
      </div>
    </button>
  </div>
</template>
<script setup lang="ts">
/* eslint-disable vue/no-mutating-props */
const { t: $t } = useI18n();

const props = defineProps<{
  question: OgApi.ServiceQuestionWithAnswer;
}>();

const availableDays = ref(
  [
    $t("request_flow.days.monday"),
    $t("request_flow.days.tuesday"),
    $t("request_flow.days.wednesday"),
    $t("request_flow.days.thursday"),
    $t("request_flow.days.friday"),
    $t("request_flow.days.saturday"),
    $t("request_flow.days.sunday"),
  ].map((day, index) => ({
    text: day,
    value: index + 1,
  })),
);

onMounted(() => {
  // assign default (+ default type Array)
  if (!props.question.answer) {
    props.question.answer = [1, 2, 3, 4, 5];
  }
});

function toggleDay(day: number) {
  if (props.question.answer.includes(day)) {
    props.question.answer = props.question.answer
      .filter((d: number) => d !== day)
      .sort();
  } else {
    props.question.answer = [...props.question.answer, day].sort();
  }
}

function isSelected(day: number) {
  return props.question.answer?.includes(day);
}
</script>
