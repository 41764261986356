export function useResponseTime(responseTime?: number | null) {
  const { t: $t } = useI18n();

  if (typeof responseTime !== "number") {
    return {
      responseTimeText: "",
    };
  }

  let responseTimeText = "";
  if (responseTime < 6) {
    responseTimeText = $t("badges.responds_hours");
  }
  if (responseTime >= 6 && responseTime < 8) {
    responseTimeText = $t("badges.responds_day");
  }
  if (responseTime >= 8 && responseTime < 16) {
    responseTimeText = $t("badges.responds_few_days");
  }
  if (responseTime >= 16 && responseTime < 24) {
    responseTimeText = $t("badges.responds_few_more_days");
  }
  if (responseTime >= 24 && responseTime < 40) {
    responseTimeText = $t("badges.responds_week");
  }

  return {
    responseTimeText,
  };
}
