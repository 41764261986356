import validate from "/home/forge/officeguru.dk/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45route_45change_45global from "/home/forge/officeguru.dk/middleware/01.route-change.global.ts";
import _02_45pass_45route_45queries_45global from "/home/forge/officeguru.dk/middleware/02.pass-route-queries.global.ts";
import _03_45capture_45back_45button_45global from "/home/forge/officeguru.dk/middleware/03.capture-back-button.global.ts";
import _04_45scroll_45to_45top_45global from "/home/forge/officeguru.dk/middleware/04.scroll-to-top.global.ts";
export const globalMiddleware = [
  validate,
  _01_45route_45change_45global,
  _02_45pass_45route_45queries_45global,
  _03_45capture_45back_45button_45global,
  _04_45scroll_45to_45top_45global
]
export const namedMiddleware = {}