<template>
  <!-- Overview -->
  <div
    v-if="showingOverview"
    class="fade-in fixed left-0 top-0 z-10 flex h-full w-full items-start justify-center overflow-y-auto bg-grey-900/90"
  >
    <BaseButton
      class="absolute right-4 top-4 border-none bg-transparent text-h5-lg"
      @click="emit('showOverview', false)"
    >
      <BaseIcon icon="close" :size="16" class="mr-1" />
      {{ $t("vendor_page.close") }}
    </BaseButton>
    <div
      class="mt-32 grid w-[80%] grid-cols-1 gap-8 pb-16 md:grid-cols-2 lg:grid-cols-3"
    >
      <!-- we have no idea about image dimensions here, can not prevent layout shifts -->
      <CmsImage
        v-for="(image, index) in images"
        :key="index"
        :src="image.url"
        :alt="getImageAlt(image.description)"
        :max-width="2048"
        allow-empty-dimensions
        class="cursor-pointer"
        @click="openImageFromOverview(index)"
      />
    </div>
  </div>

  <!-- Single images -->
  <div
    v-if="showingIndex !== null"
    class="fade-in fixed left-0 top-0 z-10 h-full w-full bg-grey-900/90"
  >
    <BaseButton
      class="absolute right-4 top-4 border-none bg-transparent text-h5-lg"
      @click="emit('showIndex', null)"
    >
      <BaseIcon icon="close" :size="16" class="mr-1" />
      {{ $t("vendor_page.close") }}
    </BaseButton>
    <!-- previous image -->
    <ImageLightboxItem
      v-if="typeof previousImageIndex === 'number'"
      :index="previousImageIndex"
      :images="images"
      :class="{ 'previous-entering': previousEntering }"
      hidden
      class="-translate-x-full"
      :alt-prefix="altPrefix"
    />

    <!-- current image -->
    <ImageLightboxItem
      :index="showingIndex"
      :images="images"
      :class="{ 'leave-right': previousEntering, 'leave-left': nextEntering }"
      :alt-prefix="altPrefix"
    />

    <!-- next image -->
    <ImageLightboxItem
      v-if="typeof nextImageIndex === 'number'"
      :index="nextImageIndex"
      :images="images"
      :class="{ 'next-entering': nextEntering }"
      hidden
      class="translate-x-full"
      :alt-prefix="altPrefix"
    />

    <!-- arrow left -->
    <ImageLightboxArrow direction="left" @click="goToPreviousImage" />
    <!-- arrow right -->
    <ImageLightboxArrow direction="right" @click="goToNextImage" />
  </div>
</template>

<script lang="ts" setup>
import { useUIStore } from "~/store/UIStore";
import ImageLightboxItem from "~/components/image-lightbox-item.vue";
const props = defineProps<{
  showingIndex: number | null;
  showingOverview: boolean;
  images: Array<{ description: string; url: string }>;
  altPrefix?: string;
}>();

const emit = defineEmits(["showIndex", "showOverview"]);
const uiStore = useUIStore();

watch(
  () => props.showingIndex,
  (value, oldValue) => {
    if (oldValue === null && value !== null) {
      uiStore.hideMainNav();
      uiStore.disableScroll();
    } else if (oldValue !== null && value === null) {
      uiStore.showMainNav();
      uiStore.enableScroll();
    }
  },
);

watch(
  () => props.showingOverview,
  (value) => {
    if (value) {
      uiStore.hideMainNav();
      uiStore.disableScroll();
    } else {
      uiStore.showMainNav();
      uiStore.enableScroll();
    }
  },
);

const previousImageIndex = computed(() => {
  if (props.images.length <= 1) {
    return null;
  }
  if (props.showingIndex === 0) {
    return props.images.length - 1;
  }
  if (props.showingIndex === null) return null;
  return props.showingIndex - 1;
});

const nextImageIndex = computed(() => {
  if (props.images.length <= 1) {
    return null;
  }
  if (props.showingIndex === props.images.length - 1) {
    return 0;
  }
  if (props.showingIndex === null) return null;
  return props.showingIndex + 1;
});

const previousEntering = ref(false);
const nextEntering = ref(false);
function goToPreviousImage() {
  if (previousEntering.value || nextEntering.value) {
    return;
  }
  previousEntering.value = true;
  setTimeout(() => {
    emit("showIndex", previousImageIndex.value);
    previousEntering.value = false;
  }, 300);
}

function goToNextImage() {
  if (previousEntering.value || nextEntering.value) {
    return;
  }
  nextEntering.value = true;
  setTimeout(() => {
    emit("showIndex", nextImageIndex.value);
    nextEntering.value = false;
  }, 300);
}

function openImageFromOverview(index: number) {
  emit("showIndex", index);
  emit("showOverview", false);
}

function getImageAlt(description: string) {
  if (props.altPrefix) return `${props.altPrefix} - ${description}`;
  return description;
}
</script>

<style scoped>
@keyframes previous-entering {
  0% {
    transform: translateX(-100%);
    opacity: 1;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes next-entering {
  0% {
    transform: translateX(100%);
    opacity: 1;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes leave-right {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 1;
  }
}

@keyframes leave-left {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.previous-entering {
  animation: previous-entering 0.3s ease-in-out forwards;
}

.leave-right {
  animation: leave-right 0.3s ease-in-out forwards;
}

.next-entering {
  animation: next-entering 0.3s ease-in-out forwards;
}

.leave-left {
  animation: leave-left 0.3s ease-in-out forwards;
}

.fade-in {
  animation: fade-in 0.2s ease-in-out forwards;
}
</style>
