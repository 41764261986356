const TRACKING_EVENT_TYPES = {
  // https://developers.cloudflare.com/zaraz/web-api/ecommerce/
  // These event names are based on zaraz ecommerce events and some custom track events.
  ADD_TO_CART: "add_to_cart",
  BEGIN_CHECKOUT: "begin_checkout",
  PURCHASE: "purchase",
  SIGN_UP: "sign_up",
  PRODUCT_VIEWED: "product_viewed",
  GENERATE_LEAD: "generate_lead",
  REQUEST_FLOW_STARTED: "request_flow_started",
  REQUEST_FLOW_COMPLETED: "request_flow_completed",
  VIEW_SERVICE: "view_service",
  VIEW_BLOG: "view_blog",
  VIEW_PRODUCT: "view_product",
};

const TRACKING_EVENT_HANDLING: { [key: string]: "ecommerce" | "track" } = {
  [TRACKING_EVENT_TYPES.ADD_TO_CART]: "ecommerce",
  [TRACKING_EVENT_TYPES.BEGIN_CHECKOUT]: "ecommerce",
  [TRACKING_EVENT_TYPES.PURCHASE]: "ecommerce",
  [TRACKING_EVENT_TYPES.SIGN_UP]: "track",
  [TRACKING_EVENT_TYPES.PRODUCT_VIEWED]: "ecommerce",
  [TRACKING_EVENT_TYPES.GENERATE_LEAD]: "track",
  [TRACKING_EVENT_TYPES.REQUEST_FLOW_STARTED]: "ecommerce",
  [TRACKING_EVENT_TYPES.REQUEST_FLOW_COMPLETED]: "ecommerce",
  [TRACKING_EVENT_TYPES.VIEW_SERVICE]: "track",
  [TRACKING_EVENT_TYPES.VIEW_BLOG]: "track",
  [TRACKING_EVENT_TYPES.VIEW_PRODUCT]: "track",
};

export { TRACKING_EVENT_TYPES, TRACKING_EVENT_HANDLING };
