<template>
  <BaseContent :style="backgroundImageStyle" class="bg-cover">
    <div
      class="flex flex-col-reverse items-center justify-between lg:flex-row"
      :class="[verticalPadding, alternativeLayout ? 'lg:flex-row-reverse' : '']"
    >
      <div
        class="lg:mr-8 lg:w-5/12"
        :class="{ 'lg:ml-8 lg:mr-0': alternativeLayout }"
      >
        <h1 v-if="headline" class="typo-h1 mb-5 lg:mr-4">
          {{ headline }}
        </h1>
        <WysiwygRenderer
          v-if="description"
          class="typo-body-xl"
          :content="description"
        />
        <template
          v-if="content.button_text && pathResolves(content.button_target)"
        >
          <NuxtLink
            :to="content.button_target"
            class="mt-8 inline-block"
            :target="buttonLinkTarget"
          >
            <BaseButton>
              {{ content.button_text }}
            </BaseButton>
          </NuxtLink>
        </template>
      </div>
      <div
        class="mb-8 lg:mb-0 lg:ml-8 lg:w-7/12"
        :class="{ 'lg:ml-0 lg:mr-8': alternativeLayout }"
      >
        <!-- done -->
        <CmsImage
          v-if="heroImage.url"
          :src="heroImage.url"
          :alt="heroImage.alt"
          :width="heroImage.width"
          :height="heroImage.height"
          :max-width="864"
          class="w-full"
        />
      </div>
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import { useVerticalPadding } from "~/components/cms-blocks/components/use-vertical-padding";

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
});

const headline = computed(() => {
  return props.content.header_text;
});

const description = computed(() => {
  return props.content.description;
});

const heroImage = computed(() => {
  return {
    url: props.content.hero_image?.url,
    alt: props.content.hero_image?.alt,
    width: props.content.hero_image?.width,
    height: props.content.hero_image?.height,
  };
});

const backgroundImageStyle = computed(
  () => `background-image: url(${props.content?.background_image?.url})`,
);

const alternativeLayout = computed(() => {
  return props.content.alternative_layout;
});

const verticalPadding = useVerticalPadding(props);

// TODO: Extract this when it works well enough
function pathResolves(path: string) {
  if (!path) return false;

  if (path.startsWith("http")) return true;

  const exists = useRouter().resolve(path).matched.length > 0;

  if (!exists) {
    useBugsnag().notify(
      new Error(
        `Button target ${path} does not exist in routes. Hiding button.`,
      ),
    );
  }

  return exists;
}

const buttonLinkTarget = props.content.button_target?.startsWith("http")
  ? "_blank _noopener _noreferrer"
  : "_self";
</script>
