<template>
  <BaseContent :style="backgroundStyle">
    <div
      class="flex flex-col-reverse gap-20 lg:flex-row"
      :class="{
        'lg:flex-row-reverse': flipFormPosition,
      }"
    >
      <div class="mb-8 flex justify-center md:mb-0 lg:w-5/12">
        <component :is="form" :content="props.content" />
      </div>
      <div class="flex flex-col justify-center lg:w-7/12">
        <WysiwygRenderer
          :content="content.text"
          class="typo-body-lg text-grey-700"
          :bullet-icon="bulletIconUrl"
        />
      </div>
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import { FormsCustomerSignupForm, FormsVendorSignupForm } from "#components";
import { useBackgroundColor } from "~/components/cms-blocks/components/use-background-color";
import { useBackgroundImage } from "~/components/cms-blocks/components/use-background-image";
import { useTextBulletIcon } from "~/components/cms-blocks/components/use-text-bullet-icon";

const props = defineProps<{
  content: {
    text: string;
    form_name: "customer_signup" | "vendor_signup";
    form_placement: {
      value: "left" | "right";
    };
  };
}>();

const form = computed(() => {
  const forms = {
    customer_signup: FormsCustomerSignupForm,
    vendor_signup: FormsVendorSignupForm,
  };

  const component = forms[props.content.form_name];

  if (!component) {
    throw new Error(
      `Form ${props.content.form_name} not found in forms list or value not available.`,
    );
  }

  return component;
});

const flipFormPosition = computed(() => {
  return props.content.form_placement.value !== "left";
});

const bulletIconUrl = useTextBulletIcon(props);
const backgroundColor = useBackgroundColor(props);
const backgroundImage = useBackgroundImage(props);
const backgroundStyle = backgroundImage.value
  ? backgroundImage.value
  : backgroundColor.value;
</script>
