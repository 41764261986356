<template>
  <BaseContent v-if="hasRatings">
    <h2 class="typo-h2 mb-12">{{ content.header }}</h2>
    <div class="grid gap-12 lg:grid-cols-3">
      <div v-for="rating in ratings" :key="rating.id" class="flex flex-col">
        <div class="flex w-full items-center">
          <BaseAvatar
            :image="rating.avatar_image"
            :fallback-text="rating.user_name"
            size="xx-large"
          />
          <div class="ml-6 flex flex-col overflow-hidden">
            <div class="typo-body-lg mb-1 truncate text-grey-500">
              {{
                $t("blocks.rating_columns.name_rated", {
                  name: rating.user_name,
                })
              }}
            </div>
            <NuxtLink
              class="typo-h4 truncate font-bold text-brand-600"
              :to="rating.service.url"
              >{{ rating.service.title }}</NuxtLink
            >
          </div>
        </div>
        <RatingStars
          :rating="rating.rating"
          class="mt-6 flex w-full gap-1.5"
          size="large"
        />
        <WysiwygRenderer
          class="typo-body-xl mt-3 flex flex-col text-grey-700"
          :content="rating.text"
        />
      </div>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  raw: {
    type: Object,
    required: true,
  },
});

const ratings = props.content?.ratings;

const hasRatings = computed(() => Array.isArray(ratings) && ratings.length > 0);
</script>
