import {
  TRACKING_EVENT_HANDLING,
  TRACKING_EVENT_TYPES,
} from "~/utils/tracking/tracking-shared";
function zarazTracking<T extends TrackingEventType>(
  event: TrackingEventType,
  metadata?: TrackingMetadataEventMap[T],
) {
  if (!TRACKING_EVENT_HANDLING[event]) return;
  if (
    TRACKING_EVENT_HANDLING[event] === "ecommerce" &&
    window?.zaraz?.ecommerce
  ) {
    window.zaraz.ecommerce(zarazEventMap[event], metadata);
  } else if (
    TRACKING_EVENT_HANDLING[event] === "track" &&
    window?.zaraz?.track
  ) {
    window.zaraz.track(zarazEventMap[event], metadata);
  }
}

const zarazEventMap: { [key: string]: string } = {
  [TRACKING_EVENT_TYPES.ADD_TO_CART]: "Product Added",
  [TRACKING_EVENT_TYPES.BEGIN_CHECKOUT]: "Checkout Started",
  [TRACKING_EVENT_TYPES.PURCHASE]: "Order Completed",
  [TRACKING_EVENT_TYPES.REQUEST_FLOW_STARTED]: "Product Added",
  [TRACKING_EVENT_TYPES.SIGN_UP]: "Sign Up",
  [TRACKING_EVENT_TYPES.PRODUCT_VIEWED]: "Product Viewed",
  [TRACKING_EVENT_TYPES.GENERATE_LEAD]: "Lead Generated",
  [TRACKING_EVENT_TYPES.REQUEST_FLOW_COMPLETED]: "Order Completed",
  [TRACKING_EVENT_TYPES.VIEW_SERVICE]: "View Service",
  [TRACKING_EVENT_TYPES.VIEW_BLOG]: "View Blog",
  [TRACKING_EVENT_TYPES.VIEW_PRODUCT]: "View Product",
};
export { zarazTracking };
