import { storeToRefs } from "pinia";
import { useUIStore } from "~/store/UIStore";

export default defineNuxtRouteMiddleware(() => {
  // TODO: This is a quick fix to prevent using browser back / forward inside the request flow
  // Instead of disabling we should make forward / backward go through the slides, but I haven't had the time
  // to do so.
  const { requestFlowIsShown } = storeToRefs(useUIStore());
  if (requestFlowIsShown.value) {
    return abortNavigation();
  }
});
