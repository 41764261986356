const onKeyDownCallback = (e: KeyboardEvent) => {
  if (e.code?.toUpperCase() !== "TAB") return;

  e.preventDefault();
};

export function useTabPrevent() {
  function activate() {
    // make sure we can not register multiple times
    deactivate();

    window.addEventListener("keydown", onKeyDownCallback);
  }

  function deactivate() {
    window.removeEventListener("keydown", onKeyDownCallback);
  }

  return {
    activate,
    deactivate,
  };
}
