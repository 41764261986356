<template>
  <!-- done -->
  <CmsImage
    class="col-span-1 row-span-1 aspect-[1.7] h-full w-full object-cover object-center"
    :src="image.url"
    :alt="altString"
    aria-label="View image"
    role="button"
    tabindex="0"
    :max-width="600"
    :loading="loading"
    :fetch-priority="fetchPriority"
    allow-empty-dimensions
    @click="action"
    @keydown.enter="action"
  />
</template>

<script lang="ts" setup>
interface Props {
  image: { url: string; description?: string };
  action: any;
  altPrefix?: string;
  loading?: "lazy" | "eager" | "auto";
  fetchPriority?: "high" | "low" | "auto";
}

const props = withDefaults(defineProps<Props>(), {
  loading: "lazy",
  fetchPriority: "auto",
  altPrefix: "",
});

const altString = computed(() => {
  if (props.altPrefix) return `${props.altPrefix} - ${props.image.description}`;
  return props.image.description;
});
</script>
