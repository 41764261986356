<template>
  <label
    class="flex"
    :class="[
      forCheckbox
        ? 'cursor-pointer flex-row-reverse justify-end'
        : 'flex-col flex-wrap',
    ]"
  >
    <span
      class="typo-body mb-1 mt-3 block select-none font-medium text-grey-700"
      :class="{ 'text-semantic-negative-700': forCheckbox && hasError }"
      ><span class="label" v-html="label" /><span
        v-if="required"
        class="text-grey-500"
        :class="{ 'text-semantic-negative-700': forCheckbox && hasError }"
        >*</span
      ></span
    >
    <slot />
  </label>
</template>

<script setup lang="ts">
import { useHasFieldError } from "~/utils/use-has-field-error";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  forCheckbox: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Object,
    default: null,
  },
  fieldName: {
    type: String,
    required: true,
  },
});

const hasError = useHasFieldError(props);
</script>

<style>
.label a {
  @apply text-brand-600;
}
</style>
