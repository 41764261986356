<template>
  <BaseContent>
    <SingleVendorSubpageHeader
      v-if="vendorData"
      :vendor="vendorData"
      class="mb-8"
      :additional-breadcrumbs="breadcrumbs"
    />
    <div v-if="!categoriesError" class="mb-12 lg:hidden">
      <h2 class="mb-4 text-h5-lg font-bold text-grey-900">
        {{ $t("vendor_page.products") }}
      </h2>
      <SelectDropdown
        v-if="categoriesOptions"
        v-model="selectedCategory"
        :options="categoriesOptions"
        :class="{ 'pointer-events-none opacity-30': productsLoading }"
      />
    </div>
    <div class="flex flex-row">
      <SingleVendorCategoryList
        v-if="categoriesData && !categoriesError"
        v-model="selectedCategory"
        :categories="categoriesData"
        class="hidden lg:block"
        :class="{ 'pointer-events-none opacity-30': productsLoading }"
      />
      <template v-if="productsError">
        <div class="typo-body m-auto flex self-center text-grey-700">
          <span>{{ $t("vendor_page.loading_of_products_failed") }}</span
          >&nbsp;
          <span role="button" @click="resetFilters">
            {{ $t("vendor_page.click_here_to_refresh") }}
          </span>
        </div>
      </template>
      <template v-else>
        <SingleVendorProductsTable
          v-if="vendorData && productsData"
          :products="productsData"
          :vendor="vendorData"
          additional-header-classes="hidden lg:block"
          class="flex-1"
          :class="{ 'pointer-events-none opacity-30': productsLoading }"
          grid
        />
      </template>
    </div>
    <BasePagination
      v-if="productsMeta"
      :info="productsMeta"
      :translated-resource-name="$t('vendor_page.products')"
      @click="page = $event"
    />
  </BaseContent>
</template>
<script setup lang="ts">
import SingleVendorSubpageHeader from "~/pages/components/vendor/single-vendor-subpage-header.vue";
import SingleVendorProductsTable from "~/pages/components/vendor/single-vendor-products-table.vue";
import SingleVendorCategoryList from "~/pages/components/vendor/single-vendor-category-list.vue";
import { useFetchApi } from "~/utils/use-fetch-api";

const $route = useRoute();
const { t } = useI18n();

const page = ref(1);
const selectedCategory = ref(
  (useRouter().currentRoute.value.query?.category as string) || "",
);

const { data: vendorDataRaw } = useAsyncData(
  `/public/partners/${$route.meta.vendorApiId}`,
  async () => {
    try {
      const response: any = await useFetchApi(
        `/public/partners/${$route.meta.vendorApiId}`,
        {
          query: {
            resource: "full",
          },
        },
      );
      if (!response.data?.value) return Promise.reject(response.error);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

const {
  data: productsDataRaw,
  refresh: productsRefresh,
  pending: productsLoading,
  error: productsError,
} = useAsyncData(
  `/public/partners/${$route.meta.vendorApiId}/products/`,
  async () => {
    try {
      const response: any = await useFetchApi(
        `/public/partners/${$route.meta.vendorApiId}/products`,
        {
          query: {
            page,
            category_id: selectedCategory.value,
          },
        },
      );
      if (!response.data?.value) return Promise.reject(response.error);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

const { data: categoriesDataRaw, error: categoriesError } = useAsyncData(
  `/public/partners/${$route.meta.vendorApiId}/product-categories`,
  async () => {
    try {
      const response: any = await useFetchApi(
        `/public/partners/${$route.meta.vendorApiId}/product-categories`,
        {
          query: {
            per_page: 9999,
          },
        },
      );
      if (!response.data?.value) return Promise.reject(response.error);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

watch([selectedCategory, page], ([categoryNew], [categoryOld]) => {
  const isCategoryChange = categoryNew !== categoryOld;

  if (isCategoryChange) {
    // refresh the results w. the updated category, but page 1 instead of an old page that could be
    // out of bounds
    page.value = 1;

    const isEmbedded =
      useRouter().currentRoute.value.fullPath.includes("embedded");
    useRouter().push({
      query: { embedded: isEmbedded ? null : undefined, category: categoryNew },
    });
  }

  productsRefresh();
});

const vendorData = computed((): OgApi.VendorFull | null => {
  return vendorDataRaw.value?.data || vendorDataRaw.value?.value?.data || null;
});

const productsData = computed((): OgApi.Webshop.Product[] | null => {
  return (
    productsDataRaw.value?.data || productsDataRaw.value?.value?.data || null
  );
});

const productsMeta = computed((): OgApi.ListResponseMeta | null => {
  return (
    productsDataRaw.value?.meta || productsDataRaw.value?.value?.meta || null
  );
});

const categoriesData = computed((): OgApi.Webshop.Category[] | null => {
  const categories: OgApi.Webshop.Category[] =
    categoriesDataRaw.value?.data || categoriesDataRaw.value?.value?.data || [];

  const allCategory: OgApi.Webshop.Category = {
    id: "",
    name: t("vendor_page.all_products"),
    subcategories: [],
  };

  return [allCategory, ...categories];
});

const categoriesOptions = computed(
  (): { label: string; value: string; isChild?: boolean }[] | null => {
    return (categoriesData.value || []).flatMap((category) => [
      { label: category.name, value: category.id },
      ...category.subcategories.map((subcategory) => ({
        label: `${subcategory.name}`,
        value: subcategory.id,
        isChild: true,
      })),
    ]);
  },
);

// OTHERS
const breadcrumbs = computed(() => {
  return [{ label: t("vendor_page.products") }];
});

function resetFilters() {
  selectedCategory.value = "";
  page.value = 1;
}
</script>
