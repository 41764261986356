<template>
  <div>
    <div
      v-for="(subQuestion, index) in props.question.content.fields"
      :key="index"
    >
      <component
        :is="getComponentByQuestionType(subQuestion.type)"
        :question="subQuestion"
        :index="index"
        class="mb-4"
        @update="update({ index, ...$event })"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
/* eslint-disable vue/no-mutating-props */
import { useGetComponentByQuestionType } from "~/utils/use-get-component-by-question-type";

const props = defineProps<{
  question: OgApi.ServiceQuestionWithAnswer;
}>();

const { getComponentByQuestionType } = useGetComponentByQuestionType();

function update(args: any) {
  // a composite needs to assign every question to the single answer array in the
  // order the fields are in the `contents` field
  props.question.answer[args.index] = args.answer;
}
</script>
