<template>
  <div class="border-b border-b-grey-200 py-10">
    <div class="flex justify-between">
      <h2 class="text-h5-lg font-bold text-grey-900">
        {{ $t("vendor_page.products") }}
      </h2>
      <NuxtLink
        :to="{ name: vendorProductsLinkName(vendor.slug) }"
        class="text-brand-600"
      >
        <BaseButton type="clear">{{ $t("vendor_page.view_all") }}</BaseButton>
      </NuxtLink>
    </div>
    <p class="mb-4 text-body text-grey-500">
      {{ $t("vendor_page.products_description") }}
    </p>
    <div class="rounded-lg bg-[#FAFBFF] p-4">
      <SingleVendorProductsTable
        v-if="highlightedProducts"
        :vendor="vendor"
        :products="highlightedProducts"
        grid
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import SingleVendorProductsTable from "~/pages/components/vendor/single-vendor-products-table.vue";
import { vendorProductsLinkName } from "~/utils/linking";

interface Props {
  products: OgApi.Webshop.ProductListResponse;
  vendor: OgApi.VendorFull;
}
const props = defineProps<Props>();

const highlightedProducts = computed(() => {
  return props.products?.highlighted;
});
</script>
