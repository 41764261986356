export default defineNuxtRouteMiddleware(() => {
  useNuxtApp().hook("page:finish", () => {
    // Enable this if history navigation (i.e. no fresh forward navigation) should result in going to the
    // saved scroll position. I felt it works better for us when we always scroll to the top.

    // if (history.state.scroll) {
    //   setTimeout(() => window.scrollTo(history.state.scroll), 0);
    // } else {
    setTimeout(() => window.scrollTo(0, 0), 0);
    // }
  });
});
