<template>
  <!-- Error overlay -->
  <div
    v-if="showing && errors.length"
    class="fixed bottom-0 left-0 w-full overflow-auto bg-grey-900 opacity-95 transition-all"
    :class="expanded ? 'h-[90%]' : 'h-1/2'"
  >
    <div class="flex items-center justify-between p-4 pb-0">
      <p class="text-body text-white">
        {{ errors.length }} typescript errors found.
      </p>
      <div>
        <button
          class="mr-2 rounded bg-grey-700 px-2 text-white transition-all"
          :class="expanded ? 'rotate-180' : ''"
          @click="expanded = !expanded"
        >
          ^
        </button>
        <button
          class="rounded bg-grey-700 px-4 text-white"
          @click="showing = false"
        >
          Hide
        </button>
      </div>
    </div>
    <div v-for="(error, index) in errors" :key="index" class="p-4">
      <div class="border-r-8 pr-4 font-semibold text-[#e63959]">
        {{ error.message }}
      </div>
      <p class="text-white">
        {{ error.loc?.file }}
        <span class="text-caption"
          >L: {{ error.loc?.line }} C: {{ error.loc?.column }}</span
        >
      </p>
      <pre class="bg-grey-700 text-body text-white">{{ error.frame }}</pre>
    </div>
  </div>

  <!-- Hidden overlay button -->
  <button
    v-if="!showing && errors.length"
    class="fixed bottom-2 left-2 rounded bg-grey-900 px-4 text-white opacity-80"
    @click="showing = true"
  >
    TS Errors
    <div
      class="absolute right-0 top-0 h-[20px] w-[20px] -translate-y-1/2 translate-x-1/2 rounded-full bg-semantic-negative-700 text-caption"
    >
      <div class="flex h-full items-center justify-center">
        {{ errors.length }}
      </div>
    </div>
  </button>
</template>
<script setup>
// this component enables the vueTsc checker (vite-plugin-checker) to show errors in the browser
const errors = ref([]);
const expanded = ref(false);
const showing = ref(true);
onMounted(() => {
  if (
    useRuntimeConfig().public.appEnv !== "local" ||
    !useRuntimeConfig().public.typecheckEnabled
  ) {
    return;
  }

  // subscribe to websocket-vite on the current domain
  const ws = new WebSocket(`ws://${location.host}/_nuxt/websocket-vite`);
  ws.addEventListener("open", () => {
    ws.send(
      JSON.stringify({
        type: "custom",
        event: "vite-plugin-checker",
        data: { event: "runtime-loaded" },
      }),
    );
  });

  ws.addEventListener("message", (event) => {
    const data = JSON.parse(event.data);
    // reconnect
    if (data?.data?.event === "vite-plugin-checker:reconnect") {
      errors.value = data.data.data[0].data.diagnostics;
    }

    // error
    if (data?.data?.event === "vite-plugin-checker:error") {
      errors.value = data.data.data.diagnostics;
    }
  });
});
</script>
