<template>
  <!-- TrustBox widget - Micro Review Count -->
  <!-- trustpilot-widget class is needed for JS injected by trustpilot script -->
  <div
    ref="trustbox"
    class="trustpilot-widget"
    :data-locale="language"
    data-template-id="5419b6a8b0d04a076446a9ad"
    data-businessunit-id="5c3eff391b5b890001a4a0cc"
    data-style-height="24px"
    data-style-width="100%"
    data-theme="light"
    data-min-review-count="0"
    data-style-alignment="center"
  >
    <a
      href="https://dk.trustpilot.com/review/officeguru.dk"
      target="_blank"
      rel="noopener"
      >Trustpilot</a
    >
  </div>
  <!-- End TrustBox widget -->
</template>

<script lang="ts" setup>
const trustbox = ref(null);
onMounted(() => {
  if (import.meta.client && window?.Trustpilot) {
    window.Trustpilot.loadFromElement(trustbox.value);
  }
});

const language = computed(() => {
  if (globalThis.lang === "da") {
    return "da-DK";
  }
  return "en-US";
});
</script>
