<template>
  <div v-if="question">
    <div class="mb-4">
      <h2 class="typo-h5 text-grey-900">
        {{ question.question
        }}<span v-if="!isRequired" class="text-grey-500">{{
          $t("request_flow.optional")
        }}</span>
      </h2>
      <p v-if="question.description" class="typo-body mt-2 text-grey-500">
        {{ question.description }}
      </p>
    </div>
    <component
      :is="getComponentByQuestionType(question.type)"
      :question="question"
      :answer="question.answer"
      :is-active="isActive"
      @update="update"
      @next="emit('next')"
    />
    <div class="mt-8 flex">
      <RequestFlowComponentsSlidesComponentsButtonGroup
        :is-first="isFirst"
        :disable-next="!isValid"
        @next="emit('next')"
        @back="emit('back')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGetComponentByQuestionType } from "~/utils/use-get-component-by-question-type";

interface Props {
  question?: OgApi.ServiceQuestionWithAnswer;
  isFirst?: boolean;
  isActive?: boolean;
}

const props = defineProps<Props>();

const { getComponentByQuestionType } = useGetComponentByQuestionType();
const emit = defineEmits(["next", "back"]);

// N.b: The composite type handles its own answer assignment as it's a bit more complex
function update(args: any) {
  args.question.answer = args.answer;
}

const isRequired = computed(() => {
  if (!props.question) return false;
  return props.question.content.required;
});

const isValid = computed(() => {
  if (!props.question) return false;
  // TODO: There's more than just this simple logic. Implement it.
  // add empty array to the following logic

  if (props.question.type === "composite") {
    if (props.question.content.required) {
      return props.question.answer.some((answer: any) => {
        return (
          answer !== null &&
          answer !== undefined &&
          answer !== "" &&
          !(Array.isArray(answer) && answer.length === 0)
        );
      });
    }
  }

  return !(
    props.question.content.required &&
    (!props.question.answer ||
      (Array.isArray(props.question.answer) &&
        props.question.answer?.length === 0))
  );
});
</script>
