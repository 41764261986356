export function useImageRounded(props: any) {
  return computed(() => {
    interface ImageRoundedSizes {
      [key: string]: string;
    }

    const roundedSizes: ImageRoundedSizes = {
      small: "rounded-sm",
      medium: "rounded-md",
      large: "rounded-lg",
      "x-large": "rounded-xl",
      "xx-large": "rounded-2xl",
      "xxx-large": "rounded-3xl",
      full: "rounded-full",
    };
    return roundedSizes[props.content.image_rounded.value];
  });
}
