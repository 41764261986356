<template>
  <component
    :is="component"
    :style="`background-color: ${
      props.content?.background_color || 'transparent'
    }`"
  >
    <div :class="verticalPadding">
      <h2
        v-if="props.content?.header_text"
        class="typo-h2 mb-3 md:mb-4"
        :class="{ 'text-center': props.content?.alignment.value === 'center' }"
      >
        {{ props.content.header_text }}
      </h2>
      <div
        v-if="props.content?.description"
        class="typo-body-xl mb-8 text-grey-700"
        :class="{ 'text-center': props.content?.alignment.value === 'center' }"
      >
        {{ props.content.description }}
      </div>
      <div class="relative w-full pt-[56.25%]">
        <iframe class="absolute left-0 top-0 h-full w-full" :src="videoUrl" />
      </div>
    </div>
  </component>
</template>

<script lang="ts" setup>
import { useVerticalPadding } from "~/components/cms-blocks/components/use-vertical-padding";
import { BaseContent } from "#components";

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  wrapInBaseContent: {
    type: Boolean,
    default: true,
  },
});

const videoUrl = computed(() => {
  return `https://www.youtube.com/embed/${props.content.youtube_video_id}?rel=0&hl=${globalThis.lang}`;
});

const component = computed(() => {
  return props.wrapInBaseContent ? BaseContent : "div";
});

const verticalPadding = useVerticalPadding(props);
</script>
