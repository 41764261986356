<template>
  <div class="flex items-center justify-center">
    <nav role="navigation" aria-label="Primary navigation">
      <ul>
        <li
          v-for="item in items"
          :key="item.page.id"
          class="relative ml-8 inline-block select-none whitespace-nowrap text-body font-medium text-grey-700 first:ml-0 hover:text-grey-900"
        >
          <!-- parent -->
          <NuxtLink
            v-if="item.children.length === 0"
            :to="item.page.url"
            @click="closeAllSubMenus"
          >
            {{ item.page.title }}
          </NuxtLink>
          <span
            v-else
            role="button"
            aria-haspopup="true"
            :aria-expanded="subMenuOpenState[item.page.id]"
            tabindex="0"
            class="cursor-pointer"
            @click="toggleSubMenuDisplay(item.page.id)"
            @keydown.space.enter="toggleSubMenuDisplay(item.page.id)"
            >{{ item.page.title }}
            <BaseIcon
              class="-mb-0.5 select-none text-grey-500 transition-transform duration-300"
              :class="{ 'rotate-180': isOpenSubmenu(item.page.id) }"
              icon="caret-down"
              :size="13"
            />
          </span>

          <!-- children -->
          <transition
            enter-active-class="transition-opacity duration-300 ease-in-out"
            leave-active-class="transition-opacity duration-300 ease-in-out"
            enter-from-class="opacity-0"
            leave-to-class="opacity-0"
          >
            <MainMenuOverlayContainer
              v-show="subMenuOpenState[item.page.id]"
              class="top-9 grid w-[446px] grid-cols-2"
              :class="{ 'right-[-20px]': isEmbedded }"
              @mouseleave="closeAllSubMenus"
            >
              <NuxtLink
                v-for="child in item.children"
                :key="child.page.id"
                :to="child.page.url"
                class="cursor-pointer rounded-lg p-2 last:col-span-2 last:mt-2 last:font-semibold last:text-brand-600 hover:bg-grey-100"
                @click="closeAllSubMenus"
              >
                {{ child.page.title }}
              </NuxtLink>
            </MainMenuOverlayContainer>
          </transition>
        </li>
      </ul>
    </nav>
    <template v-if="!isEmbedded">
      <BaseIcon
        class="ml-8 mr-[calc(1rem-2px)] flex w-[2px] text-grey-200"
        icon="divider"
        :size="16"
      />
      <nav
        v-if="!hasUser"
        role="navigation"
        aria-label="Login and signup"
        class="flex items-center"
      >
        <PageHeaderLoginButton class="mr-2" size="small" />
        <PageHeaderSignUpButton size="small" />
      </nav>
      <template v-else>
        <NuxtLink :to="customerAppUrl" external>
          <BaseButton type="outline" size="small" class="ml-4">{{
            $t("go_to_app")
          }}</BaseButton>
        </NuxtLink>
        <BaseAvatar
          :image="null"
          :fallback-text="userName"
          size="small"
          class="-my-4 ml-6"
        />
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";
import { useUserStore } from "~/store/UserStore";

const isEmbedded = useUIStore().isEmbedded;
const customerAppUrl = useRuntimeConfig().public.customerAppUrl;

const hasUser = computed(() => {
  return !!useUserStore().user?.id;
});

const userName = computed(() => {
  return useUserStore().user?.name || "";
});

const props = defineProps<{
  items: any;
}>();

interface SubMenuOpenState {
  [key: string]: boolean;
}
// sub menu related
const subMenuOpenState: Ref<SubMenuOpenState> = ref({});
onMounted(() => {
  subMenuOpenState.value = (props.items?.value || []).reduce(
    (acc: SubMenuOpenState, cur: { page: { id: string } }) => {
      acc[cur.page.id] = false;
      return acc;
    },
    {},
  );
});

function toggleSubMenuDisplay(id: string) {
  const currentState = subMenuOpenState.value[id];
  closeAllSubMenus();
  subMenuOpenState.value[id] = !currentState;
}

function closeAllSubMenus() {
  Object.keys(subMenuOpenState.value).forEach((key) => {
    subMenuOpenState.value[key] = false;
  });
}

function isOpenSubmenu(id: string) {
  return subMenuOpenState.value[id];
}

function onDocumentClick(event: MouseEvent) {
  const target = event.target as HTMLElement;
  if (!target.closest('nav[role="navigation"]')) {
    closeAllSubMenus();
  }
}

onMounted(() => {
  globalThis.document.addEventListener("click", onDocumentClick);
});

onUnmounted(() => {
  globalThis.document.removeEventListener("click", onDocumentClick);
});
// end sub menu related
</script>
