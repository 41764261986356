<template>
  <input
    ref="inputRef"
    type="text"
    class="typo-body block w-full max-w-[15.5rem] rounded-md border border-grey-300 p-2 text-grey-900"
    :value="question.answer"
    :placeholder="question.content.placeholder || ''"
    @input="update"
  />
</template>
<script setup lang="ts">
/* eslint-disable vue/no-mutating-props */
const props = defineProps<{
  question: OgApi.ServiceQuestionWithAnswer;
  isActive?: boolean;
}>();

const inputRef = ref();

watch(
  () => props.isActive,
  (newValue) => {
    if (!newValue) return;
    nextTick(() => {
      inputRef.value.focus({ preventScroll: true });
    });
  },
  {
    immediate: true,
  },
);

const emit = defineEmits(["update"]);

onMounted(() => {
  // assign default (+ default type Array)
  if (!props.question.answer) {
    props.question.answer = "";
    update(null);
  }
});

function update($event: Event | null) {
  emit("update", {
    question: props.question,
    answer: !$event ? "" : ($event.target as HTMLInputElement).value,
  });
}
</script>
