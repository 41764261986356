<template>
  <NuxtLoadingIndicator
    :class="dynamicClasses"
    color="repeating-linear-gradient(to right,#9DB4FF 0%,#5B1BF0 100%)"
    :throttle="0"
  />
</template>

<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";
import { useOfficehub } from "~/utils/use-officehub";

const { isOfficehubImpersonation } = useOfficehub();
const isEmbedded = useUIStore().isEmbedded;

const dynamicClasses = computed(() => {
  if (isEmbedded) {
    if (!isOfficehubImpersonation.value) {
      return "mt-[113px] md:mt-[63px] md:!left-[208px]";
    }
    return "mt-[153px] md:mt-[103px] md:!left-[208px]";
  }
  if (!isOfficehubImpersonation.value) {
    return "mt-[63px]";
  }
  return "mt-[103px]";
});
</script>
