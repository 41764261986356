<template>
  <div v-if="badgesFormatted.length" class="flex gap-2">
    <BaseBadge
      v-for="(badge, index) in badgesFormatted"
      :key="index"
      :text="badge?.text || ''"
      :icon-name="badge?.iconName"
      :color="badge?.key === 'full_capacity' ? 'yellow' : 'blue'"
    />
  </div>
</template>

<script setup lang="ts">
const { t: $t } = useI18n();

interface Props {
  badges: OgApi.VendorBadges;
  fullCapacity?: boolean;
}

const props = defineProps<Props>();

interface Badge {
  iconName: BaseIconName;
  text: string;
  key: string;
}

interface Badges {
  in_high_demand?: Badge;
  responds_fast?: Badge;
  top_vendor?: Badge;
  full_capacity?: Badge;
}

const badgesFormatted = computed(() => {
  const badges: Badges = {
    in_high_demand: {
      key: "high_demand",
      iconName: "high_demand",
      text: $t("badges.high_demand"),
    },
    responds_fast: {
      key: "fast_response",
      iconName: "fast_response",
      text: $t("badges.fast_response"),
    },
    top_vendor: {
      key: "top_vendor",
      iconName: "top_vendor",
      text: $t("badges.top_vendor"),
    },
    full_capacity: {
      key: "full_capacity",
      iconName: "shopping_cart_no",
      text: $t("badges.full_capacity"),
    },
  };

  const formatted = Object.keys(props.badges)
    .filter((key) => props.badges[key as keyof typeof badges])
    .map((key) => badges[key as keyof typeof badges])
    .filter((badge) => !!badge);

  if (props.fullCapacity) {
    formatted.push(badges.full_capacity);
  }
  return formatted;
});
</script>
