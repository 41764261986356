import { useAuthStore } from "~/store/AuthStore";
import { useRuntimeConfig } from "#imports";

export const useUserStore = defineStore("UserStore", () => {
  const $config = useRuntimeConfig();
  const user: Ref<OgApi.User | null> = ref(null);
  const userLoading: Ref<boolean> = ref(false);
  const locations: Ref<OgApi.Location[]> = ref([]);
  const locationsLoading: Ref<boolean> = ref(false);

  const userHasAcceptedGdpr = computed(() => {
    return user.value?.gdpr;
  });

  useAuthStore().$subscribe(
    async (_, state) => {
      !state.token ? clear() : await Promise.all([getUser(), getLocations()]);
    },
    { detached: true },
  );

  async function getUser() {
    if (!useAuthStore().token) return;
    userLoading.value = true;

    try {
      const { data }: any = await globalThis.$fetch(
        `${$config.public.apiUrl}me`,
        {
          headers: {
            Authorization: `Bearer ${useAuthStore().token}`,
            Accept: "application/json",
          },
          params: {
            "with[]": "customer.contact",
          },
        },
      );

      user.value = data;
    } finally {
      userLoading.value = false;
    }
  }

  async function getLocations() {
    if (!useAuthStore().token) return;
    locationsLoading.value = true;

    try {
      const { data }: any = await globalThis.$fetch(
        `${$config.public.apiUrl}customer/locations`,
        {
          headers: {
            Authorization: `Bearer ${useAuthStore().token}`,
            Accept: "application/json",
          },
        },
      );

      locations.value = data;
    } finally {
      locationsLoading.value = false;
    }
  }

  function acceptGdpr() {
    return globalThis.$fetch(`${$config.public.apiUrl}me`, {
      headers: {
        Authorization: `Bearer ${useAuthStore().token}`,
        Accept: "application/json",
      },
      method: "PATCH",
      body: {
        gdpr: true,
      },
    });
  }

  function clear() {
    user.value = null;
    locations.value = [];
  }

  return {
    user,
    userLoading,
    locations,
    locationsLoading,
    userHasAcceptedGdpr,
    acceptGdpr,
  };
});
