<template>
  <div itemscope itemtype="https://schema.org/Offer">
    <SingleServiceHero :service="service" />
    <BaseContent>
      <div class="grid grid-cols-3 gap-14">
        <div
          class="col-span-3 lg:col-span-2"
          :class="{ 'order-last lg:order-none': REQUEST_FLOW_ACTIVATED }"
        >
          <AnchorList
            class="mb-10"
            :items="anchors"
            @scroll-to="smoothScrollToElement"
          />
          <template v-if="hasDescriptionInfo">
            <h2 class="mb-2 text-h5-lg font-bold text-grey-900">
              {{ service.headline }}
            </h2>
            <ExpandableDescription
              ref="descriptionRef"
              :description="service.description"
              itemprop="description"
            />
          </template>
          <SingleServiceVendors
            v-if="hasVendors"
            ref="vendorsRef"
            class="mb-4"
            :service="service"
          />
          <FaqList v-if="hasFaq" ref="faqRef" :items="service.faq" />
        </div>
        <template v-if="!REQUEST_FLOW_ACTIVATED">
          <div class="hidden lg:block">
            <SingleServiceContact
              v-if="cmsValues?.link_customer_signup"
              class="sticky top-32"
              :service="service"
              sticky
              :create-account-link="cmsValues?.link_customer_signup || ''"
            />
          </div>
        </template>
        <template v-else>
          <div class="col-span-3 block md:col-span-2 lg:col-span-1">
            <GetOfferBox
              sticky
              :service-name="service.title"
              posthog-location="service_page"
            />
          </div>
        </template>
      </div>
    </BaseContent>
    <SingleServiceReviews
      v-if="hasReviews"
      ref="ratingsRef"
      :service="service"
    />
    <SingleServiceOthersBought
      v-if="hasOthersBought"
      ref="othersRef"
      :service="service"
    />
    <SingleServiceWhyOfficeguru v-if="!isEmbedded" />
    <template v-if="!REQUEST_FLOW_ACTIVATED">
      <MobileWrappedContactForm
        v-if="cmsValues?.link_customer_signup"
        class="block lg:hidden"
        :service="service"
        :create-account-link="cmsValues.link_customer_signup"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { track } from "~/utils/tracking/tracking";
import SingleServiceHero from "~/pages/components/single-service-hero.vue";
import SingleServiceContact from "~/pages/components/wrapped-contact-form.vue";
import AnchorList from "~/pages/components/anchor-list.vue";
import ExpandableDescription from "~/pages/components/expandable-description.vue";
import SingleServiceOthersBought from "~/pages/components/single-service-others-bought.vue";
import SingleServiceWhyOfficeguru from "~/pages/components/single-service-why-officeguru.vue";
import SingleServiceReviews from "~/pages/components/single-service-reviews.vue";
import SingleServiceVendors from "~/pages/components/single-service-vendors.vue";
import FaqList from "~/pages/components/faq-list.vue";
import MobileWrappedContactForm from "~/pages/components/mobile-wrapped-contact-form.vue";
import { useCMSStore } from "~/store/CMSStore";
import GetOfferBox from "~/pages/components/get-offer-box.vue";
import { useUIStore } from "~/store/UIStore";

const { isEmbedded } = useUIStore();
const { t } = useI18n();
const $route = useRoute();
const { cmsValues } = useCMSStore();

const REQUEST_FLOW_ACTIVATED =
  cmsValues?.enable_request_flow || $route.query.rf;
const { data: serviceData }: any = await useAsyncData(
  $route.path as string,
  () => {
    return $fetch($route.meta.apiUrl as string);
  },
);
const service = computed<Service>(() => serviceData.value?.data);

useSeo(service.value?.seo);

const hasDescriptionInfo = computed(() => {
  return (
    !!service.value?.description?.length && !!service.value?.headline?.length
  );
});

const hasFaq = computed(() => {
  return !!service.value?.faq?.length;
});

const hasReviews = computed(() => {
  return !!service.value?.reviews?.length;
});

const hasOthersBought = computed(() => {
  return !!service.value?.others_also_bought?.length;
});

const hasVendors = computed(() => {
  // Guess this should be true because the empty state needs to be handled in the component
  return true;
});

const descriptionRef = ref();
const vendorsRef = ref();
const faqRef = ref();
const ratingsRef = ref();
const othersRef = ref();
const anchors = [
  {
    ref: descriptionRef,
    label: t("service_page.description"),
    show: hasDescriptionInfo.value,
  },
  {
    ref: vendorsRef,
    label: t("service_page.vendors"),
    show: hasVendors.value,
  },
  {
    ref: faqRef,
    label: t("service_page.faq"),
    show: hasFaq.value,
  },
  {
    ref: ratingsRef,
    label: t("service_page.reviews"),
    show: hasReviews.value,
  },
  {
    ref: othersRef,
    label: t("service_page.others_bought"),
    show: hasOthersBought.value,
  },
];

function smoothScrollToElement(ref: Ref) {
  // need to have an OFFSET to account for the sticky header which is 64px & it
  // looked a lot nice with an extra 8px of space between the header and the element
  const OFFSET = 72;
  const element = ref?.value?.$el as HTMLElement;

  if (!element) {
    return;
  }

  const top =
    element.getBoundingClientRect().top + globalThis.pageYOffset - OFFSET;

  globalThis.scrollTo({
    top,
    behavior: "smooth",
  });
}

onMounted(() => {
  track({
    event: "view_service",
    metadata: {
      service_id: service.value?.id,
      service_name: service.value?.title,
    },
    posthogEvent: {
      name: "View service",
      properties: {
        service_id: service.value?.id,
        service_name: service.value?.title,
      },
    },
  });
});
</script>
