<template>
  <div class="mx-auto flex max-w-[400px] animate-redraw flex-col">
    <h2 class="typo-h5 mb-2 text-grey-900">
      {{ $t("request_flow.almost_there_sign_up") }}
    </h2>
    <div class="typo-body mb-8 mt-4 rounded-xl bg-grey-50 p-4 font-semibold">
      <div class="flex items-center">
        <BaseIcon icon="user" class="mr-3 text-brand-600" :size="14" />
        {{ $t("request_flow.do_you_already_have_an_account") }}
        <span
          role="button"
          class="ml-1 cursor-pointer font-medium text-brand-600"
          @click="emit('show-login', true)"
          >{{ $t("request_flow.log_in") }}</span
        >
      </div>
    </div>
    <div>
      <FormsCustomerSignupForm
        :content="{
          terms_of_service: {
            url: cmsValues?.link_terms_of_payment,
          },
        }"
        :used-in-flow="true"
        @success="setTokenAndEmitSuccess"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from "~/store/AuthStore";
import { useCMSStore } from "~/store/CMSStore";
const emit = defineEmits(["success", "show-login"]);
const { setToken } = useAuthStore();
const { cmsValues } = useCMSStore();

function setTokenAndEmitSuccess(token: string) {
  setToken(token);
  emit("success");
}
</script>
