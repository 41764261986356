<template>
  <div class="flex flex-col border-b border-b-grey-200 pb-10">
    <h3 class="mb-2 text-body font-semibold text-grey-900">
      {{ $t("vendor_page.overview") }}
    </h3>
    <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
      <div
        v-if="vendor.badges"
        class="flex items-start gap-2 empty:hidden md:flex-col"
      >
        <VendorBadges :badges="vendor.badges" class="flex-wrap gap-2" />
      </div>
      <div
        v-if="yearsInBusiness || vendor.number_of_employees"
        class="flex items-start gap-2 md:flex-col"
      >
        <BaseLabel v-if="yearsInBusiness" class="max-w-full truncate text-body">
          <span class="-mb-0.5 mr-0.5 text-caption font-medium">{{
            yearsInBusiness
          }}</span>
          {{ lowerFirst($t("vendor_page.years_in_business")) }}
        </BaseLabel>
        <BaseLabel
          v-if="vendor.number_of_employees || vendor.number_of_employees === 0"
          class="max-w-full truncate text-body"
          itemprop="numberOfEmployees"
          itemscope
          itemtype="https://schema.org/QuantitativeValue"
        >
          <span
            class="-mb-0.5 mr-0.5 text-caption font-medium"
            itemprop="value"
            >{{ vendor.number_of_employees }}</span
          >
          {{ lowerFirst($t("vendor_page.employees")) }}
        </BaseLabel>
      </div>
      <div
        class="flex items-center gap-4"
        itemprop="employee"
        itemscope
        itemtype="https://schema.org/Person"
      >
        <div
          v-if="vendorProfileImage || ownerInitials"
          class="flex h-[48px] w-[48px] min-w-[48px] items-center justify-center rounded-full border border-grey-200 bg-grey-50 bg-cover bg-center text-body text-semantic-info-800"
          :style="vendorProfileImage"
        >
          <span v-if="!vendorProfileImage">{{ ownerInitials }}</span>
          <meta
            v-if="vendorProfileImage"
            itemprop="image"
            :content="vendor?.owner?.image || ''"
          />
        </div>
        <div class="flex flex-col items-start">
          <p class="text-body text-grey-900">
            {{ $t("vendor_page.account_owner") }}
          </p>
          <p class="text-body text-grey-500" itemprop="name">
            {{ vendor?.owner?.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import lowerFirst from "lodash/lowerFirst";
import { useCmsImage } from "~/utils/use-cms-image";
const props = defineProps<{
  vendor: OgApi.VendorFull;
}>();

const vendorProfileImage = computed(() => {
  return props.vendor?.owner?.image
    ? `background-image: url("${
        useCmsImage({
          src: props.vendor.owner.image,
          params: "width=48&height=48",
        }).value
      }")`
    : "";
});

const yearsInBusiness = computed(() => {
  if (!props.vendor?.establishing_year) return null;
  return new Date().getFullYear() - props.vendor?.establishing_year;
});

const ownerInitials = computed(() => {
  return props.vendor?.owner?.name
    ? props.vendor.owner.name
        .split(" ")
        .slice(0, 3)
        .map((name: string) => name[0].toUpperCase())
        .join("")
    : "";
});
</script>
