<template>
  <BaseContent class="bg-grey-50">
    <div
      class="flex flex-col items-start justify-between gap-8 py-16 lg:flex-row lg:items-center"
    >
      <div class="lg:w-1/2">
        <!-- done -->
        <CmsImage
          itemprop="image"
          :src="image.url"
          :alt="image.alt"
          :width="image.width"
          :height="image.height"
          class="w-full rounded-xl"
          :max-width="864"
          loading="eager"
          fetch-priority="high"
        />
      </div>
      <div class="lg:w-1/2">
        <p
          class="typo-body mb-2 font-semibold capitalize text-grey-500"
          itemprop="datePublished"
        >
          {{ formattedDate }}
        </p>
        <h1 class="typo-h2 font-semibold text-grey-700" itemprop="headline">
          {{ title }}
        </h1>
      </div>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
import { format } from "date-fns";

interface Props {
  title: string;
  date: string;
  image: {
    url: string;
    alt?: string;
    width: number;
    height: number;
  };
}

const props = defineProps<Props>();
const formattedDate = computed(() => format(new Date(props.date), "MMMM d, y"));
</script>
