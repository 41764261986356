<template>
  <component
    :is="isNested ? 'div' : BaseContent"
    :style="background"
    :class="verticalPadding"
  >
    <div
      class="flex flex-col"
      :class="{ 'text-center': centerContent, 'items-center': centerContent }"
    >
      <h3 class="typo-h2">
        {{ content.headline }}
      </h3>
      <WysiwygRenderer
        v-if="hasText"
        class="typo-body-xl mt-5 text-grey-700 md:mt-6"
        :content="content.text"
      />
      <BaseButton
        v-if="button"
        :href="button.link"
        :class="hasText ? 'mt-3 md:mt-5' : 'mt-6 md:mt-8'"
        size="large"
      >
        {{ button.label }}
      </BaseButton>
    </div>
  </component>
</template>

<script setup lang="ts">
import BaseButton from "~/components/base-button.vue";
import { useVerticalPadding } from "~/components/cms-blocks/components/use-vertical-padding";
import { useHasText } from "~/components/cms-blocks/components/use-has-text";
import { useCmsImage } from "~/utils/use-cms-image";
import { BaseContent } from "#components";

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
  isNested: {
    type: Boolean,
    default: false,
  },
});

const verticalPadding = useVerticalPadding(props);

// @TODO: Missing in API response
const centerContent = computed(() => {
  return props.content.alignment.value === "center";
});

const background = computed(() => {
  if (props.content?.background_image) {
    return `background-image: url('${
      useCmsImage({
        src: props.content.background_image?.url,
        params: "width=1280",
      }).value
    }');background-size: cover;background-position: center;`;
  }
  if (props.content?.background_color) {
    return `background-color: ${props.content.background_color};`;
  }
  return "";
});

const button = computed(() => {
  if (!props.content.button_link || !props.content.button_label) {
    return null;
  }
  return {
    label: props.content.button_label,
    link: props.content.button_link,
  };
});

const hasText = useHasText(props);
</script>
