<template>
  <div class="flex items-center justify-center" data-cy="view-lottie-animation">
    <div
      ref="animationContainer"
      class="lottie-animation"
      :style="`width: ${width}; height: ${height};`"
    />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  file: any;
  noLoop?: boolean;
  height?: string;
  width?: string;
}

const props = withDefaults(defineProps<Props>(), {
  noLoop: false,
  height: "auto",
  width: "150px",
});

const animationContainer = ref(null);

onMounted(async () => {
  if (!animationContainer.value) return;
  const lottie = await import("lottie-web");
  (lottie as any).loadAnimation({
    container: animationContainer.value, // the dom element that will contain the animation
    renderer: "svg",
    loop: !props.noLoop,
    autoplay: true,
    animationData: props.file,
  });
});
</script>
