<template>
  <div class="pt-10">
    <div class="mb-4 flex flex-col justify-between lg:flex-row lg:gap-3">
      <div class="flex items-center gap-2">
        <h5 class="text-h5-lg font-bold text-grey-900">
          {{ $t("service_page.type_vendors", { type: service.title }) }}
          <span v-if="zip">{{ $t("service_page.near_you") }}</span>
        </h5>
      </div>
    </div>
    <div class="flex flex-col justify-between md:flex-row">
      <div class="flex items-center gap-2">
        <p class="typo-body font-medium text-grey-700">
          {{ $t("service_page.deliver_to_zip") }}:
        </p>
        <input
          v-if="!zip"
          ref="zipElement"
          v-model="zipValue"
          type="number"
          max="9999"
          min="1000"
          class="typo-body w-[70px] rounded-lg border border-grey-300 p-1 text-grey-700 [appearance:textfield] placeholder:text-grey-300 focus:outline-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          :placeholder="$t('service_page.input_zip')"
        />
        <div
          v-else
          class="typo-body flex cursor-pointer items-center gap-1 rounded-lg border border-grey-100 bg-grey-50 px-1 text-grey-500"
          @click="resetZip"
        >
          <BaseIcon icon="location" :size="12" class="text-grey-700" />
          {{ zipValue }}
          <BaseIcon icon="close" :size="12" class="text-grey-400" />
        </div>
      </div>

      <div class="flex justify-between">
        <div class="flex items-center gap-1 text-body text-grey-500 lg:hidden">
          <BaseIcon v-if="zip" icon="location" :size="12" />
          <p v-if="zip" class="whitespace-nowrap">
            {{ $t("service_page.delivers_to_you") }}
          </p>
        </div>
        <SelectDropdown
          v-model="sortType"
          :options="sortOptions"
          class="mt-4 md:mt-0"
        />
      </div>
    </div>

    <div v-if="vendorsError" class="text-body text-grey-900">
      {{ $t("service_page.error_loading_vendors") }}
      <!-- @vue-ignore passing the refresh function in here errors - no idea how to fix that (don't want to make a separate function just for TS to be happy) -->
      <button class="block text-brand-600" @click="vendorsRefresh">
        {{ $t("service_page.click_to_try_again") }}
      </button>
    </div>

    <SingleServiceVendorsService
      v-for="vendor in vendorsSorted"
      :key="vendor.id"
      :vendor="vendor"
    />

    <div v-if="!vendorsError && vendorsSorted.length === 0" class="text-body">
      {{ $t("service_page.no_vendors_can_deliver_to_your_zip") }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import SingleServiceVendorsService from "@/pages/components/vendor/single-service-vendors-service.vue";
import { useUIStore } from "~/store/UIStore";

const props = defineProps<{ service: Service }>();

const { t } = useI18n();
const { zipCode: zip } = storeToRefs(useUIStore());
const zipInput = ref(useUIStore().zipCode);
const zipElement = ref();

const zipValue = computed({
  get: () => zipInput.value,
  set: (value: string | number) => {
    const cappedValue = value.toString().slice(0, 4);
    zipInput.value = cappedValue;
    zipElement.value.value = cappedValue;
    useUIStore().setZipCode(value.toString().length >= 4 ? cappedValue : "");
  },
});

function resetZip() {
  zipInput.value = "";
  useUIStore().setZipCode("");
}

const {
  data: vendorsRaw,
  // pending: vendorsLoading,
  error: vendorsError,
  refresh: vendorsRefresh,
} = await useFetchApi(`/public/services/${props.service.api_id}/partners`, {
  query: {
    resource: "full",
    lang: globalThis.lang as string,
    // when adding zip conditionally [via ...() trick] it doesn't update when requesting initially w/o a zip and
    // then with a zip - adding it "statically" as we do now fixes that (but results in an empty zip query all the
    // time, but the backend seems to tolerate that
    zip,
  },
  transform: ({ data }) => data,
  watch: [zip, ref(props.service.api_id)],
});

const vendorsSorted: ComputedRef<OgApi.VendorFull[]> = computed(() => {
  if (!Array.isArray(vendorsRaw.value)) return [];

  return [...vendorsRaw.value].sort((a, b) => {
    if (sortType.value === "rating") {
      return b.rating.score - a.rating.score;
    } else {
      return a.name.localeCompare(b.title);
    }
  });
});

const sortOptions = computed(() => {
  return [
    {
      label: t("service_page.sort_by_top_rated"),
      value: "rating",
    },
    {
      label: t("service_page.sort_alphabetically"),
      value: "alphabetical",
    },
  ];
});

const sortType = ref("rating");
</script>
