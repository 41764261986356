import { useRuntimeConfig } from "#imports";

function submitForm(formName: string, data: any) {
  const $config = useRuntimeConfig();
  // const csrf = await globalThis.$fetch(`${$config.public.cmsUrl}cp/auth/token`)
  return globalThis.$fetch(`${$config.public.cmsUrl}!/forms/${formName}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
    },
  });
}

async function submitApiForm(path: string, data: any) {
  const $config = useRuntimeConfig();
  const recaptchaAction = `marketplace_${path.replace("/", "_")}_submit`;

  return globalThis.$fetch(`${$config.public.apiUrl}${path}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Recaptcha-Token":
        await useNuxtApp().$recaptcha.execute(recaptchaAction),
      "X-Recaptcha-Action": recaptchaAction,
    },
  });
}

export { submitForm, submitApiForm };
