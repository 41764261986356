<template>
  <BaseIcon
    icon="arrow-right"
    color="white"
    :size="18"
    class="absolute top-1/2 block -translate-y-1/2 cursor-pointer rounded-lg border border-grey-200 p-4 transition-all hover:bg-grey-50/10"
    :class="directionalClasses"
  />
</template>

<script lang="ts" setup>
const props = defineProps({
  direction: {
    type: String,
    required: true,
    validator: (value: string) => {
      return ["left", "right"].includes(value);
    },
  },
});

const directionalClasses = computed(() => {
  const classes = {
    left: "left-4 rotate-180",
    right: "right-4",
  };
  return classes[props.direction as keyof typeof classes];
});
</script>
