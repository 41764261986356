import { CMS_BLOCK_TYPE_COMPONENT_MAP } from "~/components/cms-blocks/block-type-component-map.const";

class CmsBlock {
  constructor() {
    throw new Error("Please don't instantiate me. I only have static methods.");
  }

  static isSupported(type: string): boolean {
    const supported = Object.keys(CMS_BLOCK_TYPE_COMPONENT_MAP).includes(type);

    if (!supported) {
      // eslint-disable-next-line no-console
      console.warn(
        `Looks like there is no support to render "${type}". How 'bout adding it? It's easy, read how in "docs/adding-new-block.md".`,
      );
    }

    return supported;
  }

  static getByType(type: string): object | undefined {
    if (!CmsBlock.isSupported(type)) {
      return;
    }

    return CMS_BLOCK_TYPE_COMPONENT_MAP[type];
  }

  static isHidden(
    conf: { key: string }[] | undefined | null,
    isEmbedded: boolean,
  ): boolean {
    // no conf, no hide
    if (!conf) return false;

    const values = conf.map(({ key }) => key);
    return isEmbedded
      ? values.includes("hide_embedded")
      : values.includes("hide_non_embedded");
  }
}

export { CmsBlock };
