<template>
  <component :is="isValid ? NuxtLink : `div`" v-bind="$attrs">
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { hasProtocol } from "ufo";
import { NuxtLinkProps } from "#app";
import { NuxtLink } from "#components";

const attrs: NuxtLinkProps = useAttrs();

const isValid = computed(() => {
  if (!attrs.to) return false;
  if (isExternal.value) return true;

  // router.hasRoute() can only check on the name, we need to check for the path
  const router = useRouter();
  const routes = router.getRoutes();
  const routeExists = routes.some((route) => {
    // @ts-ignore
    return route.name === attrs.to?.name;
  });

  if (!routeExists) {
    useBugsnag().notify(
      new Error(
        `No route found matching ${attrs.to}. Prevented crashing page by not rendering the <NuxtLink>.`,
      ),
      (event) => {
        event.addMetadata("additional", {
          attrs,
          routes: router.getRoutes(),
        });
      },
    );
    return false;
  }

  return true;
});

// <NuxtLink isExternal implementation
const isExternal = computed(() => {
  if (!attrs.to) return false;
  if (attrs.external) return true;
  if (attrs.target && attrs.target !== "_self") return true;
  if (typeof attrs.to === "object") return false;

  return attrs.to === "" || hasProtocol(attrs.to, { acceptRelative: true });
});
</script>
