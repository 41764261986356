function linkName(
  collection: "products" | "vendors" | "services",
  slug: string,
) {
  return `${collection}-${slug}`;
}

function productLinkName(slug: string) {
  return linkName("products", slug);
}

function serviceLinkName(slug: string) {
  return linkName("services", slug);
}

function vendorLinkName(slug: string) {
  return linkName("vendors", slug);
}

function vendorProductsLinkName(slug: string) {
  return `${vendorLinkName(slug)}-products`;
}

export {
  linkName,
  productLinkName,
  serviceLinkName,
  vendorLinkName,
  vendorProductsLinkName,
};
