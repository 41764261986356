<template>
  <div>
    <RequestFlowComponentsSlidesComponentsRegistrationCard
      v-if="!showLogin"
      @show-login="showLogin = $event"
      @success="emit('next-and-remove')"
    />
    <RequestFlowComponentsSlidesComponentsLoginCard
      v-else
      @show-login="showLogin = $event"
      @success="emit('next-and-remove')"
    />
  </div>
</template>

<script setup lang="ts">
const showLogin = ref(false);
const emit = defineEmits(["next-and-remove"]);

// check if we have multiple locations => add vendor selection
// check if one of the selected vendors is no longer available => add vendor selection
// all other cases, go to summary
</script>
