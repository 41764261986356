<template>
  <div class="border-b border-b-grey-200 py-10">
    <h2 class="mb-6 text-h5-lg font-bold text-grey-900">
      {{ $t("vendor_page.operational_areas") }}
    </h2>
    <p class="mb-1 text-body font-semibold text-grey-900">
      {{ $t("vendor_page.zip_ranges") }}
    </p>
    <div class="flex flex-col gap-1">
      <ExpandableDescription :description="rangeString" :cut-off="78" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ExpandableDescription from "~/pages/components/expandable-description.vue";

const props = defineProps({
  vendor: {
    type: Object,
    required: true,
  },
});

const rangeString = computed(() => {
  return props.vendor.zip_ranges
    .map((range: { from: string; to: string }) => `${range.from} - ${range.to}`)
    .join("<br />");
});
</script>
