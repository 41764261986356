<template>
  <component
    :is="headerComponent"
    v-if="navigationItems"
    :navigation-items="navigationItems"
  />
</template>

<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";
import { PageHeaderStandard, PageHeaderEmbedded } from "#components";

async function fetchMainMenu(): Promise<any> {
  const response: any = await $fetch(
    `${useRuntimeConfig().public.cmsUrl}api/navs/main_menu/tree`,
  );

  // strip everything we need already here, else the server
  // will return the gigantic payload within our html xD
  return response.data.map(({ page, children }: any) => {
    return {
      page: {
        id: page.id,
        title: page.title,
        url: page.url,
      },
      children: children?.map((c: any) => {
        return {
          page: {
            id: c.page.id,
            title: c.page.title,
            url: c.page.url,
          },
        };
      }),
    };
  });
}
const { data: navigationItems } = useAsyncData("mainMenu", fetchMainMenu);

const isEmbedded = useUIStore().isEmbedded;
const headerComponent = computed(() => {
  return isEmbedded ? PageHeaderEmbedded : PageHeaderStandard;
});
</script>
