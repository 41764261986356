<template>
  <div class="tooltip-container relative cursor-default">
    <slot />
    <div
      class="tooltip typo-body pointer-events-none absolute inline-block rounded-lg bg-grey-900 px-4 py-2.5 text-white opacity-0"
      :class="classes"
    >
      {{ text }}
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  anchorTop: {
    type: Boolean,
    default: false,
  },
  anchorLeft: {
    type: Boolean,
    default: false,
  },
  anchorRight: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
  },
  tooltipClass: {
    type: String,
    default: "",
  },
});

const classes = computed(() => {
  return [
    props.anchorTop ? "bottom-full mb-3" : "top-full mt-3",
    props.fullWidth ? "w-full" : "",
    props.anchorLeft ? "!left-0 translate-x-0" : "",
    props.anchorRight ? "!right-0 translate-x-0" : "",
    !props.anchorLeft && !props.anchorRight ? "left-1/2 -translate-x-1/2" : "",
    props.tooltipClass,
  ];
});
</script>

<style scoped>
.tooltip {
  transition: opacity 0.2s ease-in-out;
}
.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
</style>
