<template>
  <NuxtLink
    v-if="props.href"
    :to="props.href"
    :target="blank ? '_blank' : ''"
    tabindex="-1"
  >
    <button
      type="button"
      :class="`${typeStyling} ${calculatedSizeStyling} ${buttonClasses} ${disabledStyling}`"
      :disabled="disabled"
      class="flex items-center justify-center gap-1 rounded-lg border border-solid font-medium focus:outline-1 focus:outline-grey-200"
    >
      <slot />
    </button>
  </NuxtLink>
  <button
    v-else
    type="button"
    :class="`${typeStyling} ${calculatedSizeStyling} ${buttonClasses} ${disabledStyling}`"
    :disabled="disabled"
    class="flex items-center justify-center gap-1 rounded-lg border border-solid font-medium focus:outline-1 focus:outline-grey-200"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
interface Props {
  href?: string | null;
  blank?: boolean;
  type?: "primary" | "clear" | "outline";
  color?: "purple-brand" | "black" | "white" | "black-900";
  size?: "small" | "medium" | "large" | "x-large";
  buttonClasses?: string;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  href: null,
  blank: false,
  type: "primary",
  color: "purple-brand",
  size: "medium",
  buttonClasses: "",
  disabled: false,
});

const calculatedSizeStyling = computed(() => {
  return sizeStyling[props.size as keyof typeof sizeStyling];
});

const sizeStyling = {
  small: "py-1 px-4 text-body",
  medium: "py-2 px-6 text-body",
  large: "py-3 px-8 text-body",
  "x-large": "py-3 px-8 text-body-xl-lg",
};

const colors = {
  "purple-brand": {
    text: "text-brand-600",
    background: "bg-brand-600",
    border: "border-brand-600",
  },
  black: {
    text: "text-grey-700",
    background: "bg-black",
    border: "border-black",
  },
  white: {
    text: "text-white",
    background: "bg-white",
    border: "border-white",
  },
  "black-900": {
    text: "text-grey-900",
    background: "bg-black",
    border: "border-black",
  },
};

const typeStyling = computed(() => {
  const selected = colors[props.color as keyof typeof colors];
  const types = {
    primary: `${selected.background} text-white border-transparent`,
    clear: `bg-transparent ${selected.text} border-transparent`,
    outline: `bg-transparent ${selected.text} border ${selected.border}`,
  };

  return types[props.type];
});

const disabledStyling = computed(() => {
  return props.disabled ? "pointer-events-none opacity-50" : "";
});
</script>
