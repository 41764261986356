import { useLocalStorage, useDebounceFn } from "@vueuse/core";
import { HTTPMethod } from "h3";
import { useI18n } from "vue-i18n";
import { toast } from "~/utils/toast";
import { useAuthStore } from "~/store/AuthStore";

export const useCartStore = defineStore("Cart", () => {
  const { t } = useI18n();
  function generalCartError(description: string, error: any) {
    if (error?.response?.status === 422) {
      getCart();
    } else {
      toast({
        type: "error",
        text: t("cart.general_error"),
      });
      useBugsnag().notify(new Error(description), (event) => {
        event.addMetadata("additional", {
          error,
        });
      });
    }
  }

  function cartApi(
    method: HTTPMethod,
    url: string = "",
    body: any = undefined,
  ) {
    return globalThis.$fetch(url, {
      method,
      body: body !== undefined ? JSON.stringify(body) : undefined,
      baseURL: `${useRuntimeConfig().public.apiUrl}public/webshop/carts`,
      headers: {
        Accept: "application/json",
        ...(useAuthStore().token
          ? { Authorization: `Bearer ${useAuthStore().token}` }
          : {}),
      },
    });
  }

  const cart = ref<OgApi.Webshop.Cart | null>(null);
  const cartLoading = ref(false);
  const cartMounted = ref(false);
  const cartSubmitting = ref(false);
  const cartValidating = ref(false);
  // CartId is only set and relied on when the user is not logged in, to fetch the actual cart and update the user on login.
  // Always rely on cart.id for any other logic, and keep cartId null when logged in.
  const cartId = useLocalStorage("cartId", null);
  const orderPreview = ref<OgApi.Webshop.OrderPreview | null>(null);
  const authStore = useAuthStore();

  authStore.$subscribe(async () => {
    // watching the authStore token directly doesn't work, as the cookie changing from initial happens during mounting, so it can not be watched.
    if (cartId.value && authStore.isLoggedIn) {
      await updateCartUser();
    }
    getCart();
  });

  watch(cart, (newCart, oldCart) => {
    if (oldCart?.id && newCart?.id && oldCart.id !== newCart.id) {
      toast({
        text: t("cart.cart_changed_verify_content"),
        duration: 0,
      });
      getOrderPreview();
    }
  });

  function getCart() {
    const loggedIn = authStore.isLoggedIn;
    if (!cartId.value && !loggedIn) {
      cartMounted.value = true;
      return;
    }
    cartLoading.value = true;
    return cartApi("GET", loggedIn ? "" : cartId.value || "")
      .then((res: any) => {
        cart.value = res.data;
      })
      .catch((e) => {
        cartId.value = null;
        cart.value = null;
        if (e.response.status !== 404 || !loggedIn) {
          generalCartError("Failed to get cart", e);
        }
      })
      .finally(() => {
        cartLoading.value = false;
        cartMounted.value = true;
      });
  }

  async function addProductToCart(productId: string, quantity: number = 1) {
    if (!cart.value) {
      await createCart();
    }
    return cartApi("POST", `/${cart.value?.id}/lines`, {
      product_variant_id: productId,
      quantity,
    })
      .then((res: any) => {
        cart.value = res.data;
      })
      .catch((e) => {
        generalCartError("Failed to add product to cart", e);
      });
  }

  function removeProductFromCart(lineId: string) {
    return cartApi("DELETE", `/${cart.value?.id}/lines/${lineId}`)
      .then((res: any) => {
        cart.value = res.data;
        getOrderPreview();
      })
      .catch((e) => {
        generalCartError("Failed removing product from cart", e);
      });
  }

  function deleteCart() {
    return cartApi("DELETE", `/${cart.value?.id}`)
      .then(() => {
        cart.value = null;
        cartId.value = null;
      })
      .catch((e) => {
        generalCartError("Failed deleting cart", e);
      });
  }

  function createCart() {
    return cartApi("POST")
      .then((res: any) => {
        cart.value = res.data;
        if (!authStore.isLoggedIn) {
          cartId.value = res.data.id;
        }
      })
      .catch(async (err) => {
        if (
          err?.data?.errors?.user_id?.[0]?.identifier === "error_og_cart_exists"
        ) {
          await getCart();
        } else {
          generalCartError("Failed creating a cart", err);
        }
      });
  }

  const editProductQuantity = useDebounceFn((lineId, quantity) => {
    return cartApi("PATCH", `/${cart.value?.id}/lines/${lineId}`, {
      quantity,
    })
      .then((res: any) => {
        cart.value = res.data;
        getOrderPreview();
      })
      .catch((e) => {
        generalCartError("Failed to edit product quantity", e);
      });
  }, 1000);

  function addOneToCartLine(lineId: string) {
    const line = cart.value?.lines.find(
      (line: OgApi.Webshop.CartLine) => line.id === lineId,
    );
    const newQuantity = line ? line.quantity + 1 : null;
    if (newQuantity !== null) {
      line.quantity = newQuantity;
      editProductQuantity(lineId, newQuantity);
    }
  }

  function subtractOneFromCartLine(lineId: string) {
    const line = cart.value?.lines.find(
      (line: OgApi.Webshop.CartLine) => line.id === lineId,
    );
    const newQuantity = line ? line.quantity - 1 : null;
    if (newQuantity !== null) {
      line.quantity = newQuantity;
      editProductQuantity(lineId, newQuantity);
    }
  }

  function getOrderPreview() {
    if (!cart.value || !cart.value?.lines?.length) return;
    return cartApi("GET", `/${cart.value?.id}/order`)
      .then((res: any) => {
        orderPreview.value = res.data;
      })
      .catch((e) => {
        if (e?.response?.status !== 404) {
          generalCartError("Failed to get order preview", e);
        }
      });
  }

  const productCountMap = computed(() => {
    const current: any = {};
    if (cart.value) {
      cart.value.lines.forEach((line: OgApi.Webshop.CartLine) => {
        current[line.product.id] = line.quantity;
      });
    }
    return current;
  });

  const productsInCartCount = computed(() => {
    return (
      cart.value?.lines?.reduce((acc: number, cur: { quantity: number }) => {
        return acc + cur.quantity;
      }, 0) || 0
    );
  });

  function updateCartUser() {
    return cartApi("POST", `/${cartId.value}/replace`, null)
      .then((res: any) => {
        cart.value = res.data;
        cartId.value = null;
      })
      .catch((e) => {
        generalCartError("Failed to update the cart user", e);
      });
  }

  function submitOrder(
    location: string | null,
    deliveryDate: Date | null,
    note: string,
  ) {
    cartSubmitting.value = true;
    return globalThis
      .$fetch("", {
        method: "POST",
        body: {
          cart_id: cart.value?.id,
          location_id: location,
          notes: note,
          delivery_date: deliveryDate,
        },
        baseURL: `${useRuntimeConfig().public.apiUrl}customer/webshop/orders`,
        headers: {
          Accept: "application/json",
          ...(authStore.token
            ? { Authorization: `Bearer ${useAuthStore().token}` }
            : {}),
        },
      })
      .catch((e) => {
        generalCartError("Failed to submit order", e);
      })
      .finally(() => {
        cartSubmitting.value = false;
      });
  }

  function removeCodeFromCart(code: string) {
    if (!cart.value?.id) {
      throw new Error("Can not validate cart as cart id is missing.");
    }

    const codeEncoded = encodeURIComponent(code);
    return useFetchApi(
      `/public/webshop/carts/${cart.value.id}/discount/${codeEncoded}`,
      {
        method: "DELETE",
      },
    );
  }

  function addCodeToCart(code: string) {
    if (!cart.value?.id) {
      throw new Error("Can not validate cart as cart id is missing.");
    }

    return useFetchApi(`/public/webshop/carts/${cart.value.id}/discount`, {
      body: {
        code,
      },
      method: "POST",
    });
  }

  async function validateDiscountsOnCart() {
    if (!cart.value?.id) {
      throw new Error("Can not validate cart as cart id is missing.");
    }

    cartValidating.value = true;
    try {
      return await useFetchApi(
        `public/webshop/carts/${cart.value.id}/validate-discounts`,
        {
          method: "GET",
        },
      );
    } finally {
      cartValidating.value = false;
    }
  }

  return {
    cart,
    orderPreview,
    addProductToCart,
    removeProductFromCart,
    deleteCart,
    editProductQuantity,
    addOneToCartLine,
    subtractOneFromCartLine,
    productCountMap,
    productsInCartCount,
    getOrderPreview,
    cartLoading,
    cartMounted,
    cartSubmitting,
    cartValidating,
    submitOrder,
    validateDiscountsOnCart,
    getCart,
    removeCodeFromCart,
    addCodeToCart,
  };
});
