<template>
  <button
    class="-my-2.5 ml-4 mr-[-5px] flex h-10 w-10 cursor-pointer items-center justify-center text-grey-900"
    @click="uiStore.toggleMobileNav()"
  >
    <span
      v-if="!uiStore.mobileNavIsShown"
      class="box-content flex items-center justify-center"
    >
      <BaseIcon icon="burger" :size="17" />
    </span>
    <span
      v-else
      class="box-content flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-grey-200"
    >
      <BaseIcon icon="close" :size="17" />
    </span>
  </button>
  <ClientOnly>
    <!-- had issues with the stacking contexts so best solution I found was teleporting it to the end of the body -->
    <Teleport to="[data-teleport=compact-navigation]">
      <nav
        v-if="uiStore.mobileNavIsShown"
        class="fixed left-0 top-16 flex h-full w-full flex-col items-center bg-white p-4 pt-10"
      >
        <ul class="text-center">
          <li
            v-for="item in items"
            :key="item.page.id"
            class="typo-body-xl mt-8 font-medium text-grey-700 first:mt-0 hover:text-grey-900"
          >
            <NuxtLink :to="item.page.url">
              {{ item.page.title }}
            </NuxtLink>
          </li>
        </ul>
        <div class="mt-8 flex">
          <PageHeaderLoginButton size="x-large" />
          <PageHeaderSignUpButton size="x-large" />
        </div>
      </nav>
    </Teleport>
  </ClientOnly>
</template>

<script setup>
import { useUIStore } from "~/store/UIStore";
const uiStore = useUIStore();

defineProps({
  items: {
    type: Array,
    required: true,
  },
});

function onWindowResize() {
  uiStore.hideMobileNav();
}

onMounted(() => {
  // hide menu when resizing so that we don't accidentally keep it open while entering the larger breakpoints
  // where it shouldn't exist
  globalThis.addEventListener("resize", onWindowResize);
});

onUnmounted(() => {
  globalThis.removeEventListener("resize", onWindowResize);
});
</script>
