<template>
  <div
    class="typo-body inline-flex items-center justify-center rounded-lg border px-1.5 py-0.5"
    :class="colorClasses[color]"
  >
    <BaseIcon v-if="iconName" :icon="iconName" class="mr-1" :size="18" /><span
      class="-mt-px whitespace-nowrap"
      >{{ text }}</span
    >
  </div>
</template>

<script setup lang="ts">
const colorClasses = {
  blue: "bg-semantic-info-50 text-semantic-info-700 border-semantic-info-100",
  yellow:
    "bg-semantic-notice-50 text-semantic-notice-700 border-semantic-notice-100",
};

withDefaults(
  defineProps<{
    iconName?: BaseIconName;
    text: string;
    color?: "blue" | "yellow";
  }>(),
  {
    iconName: undefined,
    color: "blue",
  },
);
</script>
