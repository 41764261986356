<template>
  <BaseButton
    v-if="!isFirst"
    class="-ml-6 mr-4"
    type="clear"
    color="black"
    @click="emit('back')"
    ><span class="mr-2"
      ><BaseIcon icon="arrow-down-long" class="rotate-90" /></span
    >{{ $t("request_flow.back") }}</BaseButton
  >
  <BaseButton v-if="!isLast" :disabled="disableNext" @click="emit('next')"
    >{{ nextText
    }}<span class="ml-2">
      <BaseIcon
        v-if="!customNextIcon"
        icon="arrow-down-long"
        class="-mb-2 -rotate-90"
      />
      <BaseIcon v-else :icon="customNextIcon" class="mb-1 flex" /> </span
  ></BaseButton>
</template>

<script setup lang="ts">
const { t: $t } = useI18n();

interface Props {
  disableNext?: boolean;
  disablePrev?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  customNextIcon?: BaseIconName;
  nextText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  disableNext: false,
  disablePrev: false,
  isFirst: false,
  isLast: false,
  customNextIcon: undefined,
  nextText: undefined,
});

const emit = defineEmits(["next", "back"]);

const nextText = computed(() => {
  if (props.nextText) return props.nextText;
  return $t("request_flow.next");
});
</script>
