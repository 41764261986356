<template>
  <div class="min-w-[400px]">
    <div class="flex gap-4 bg-grey-50 p-4">
      <div
        v-for="(tab, key) in tabs"
        :key="key"
        class="relative cursor-pointer whitespace-nowrap font-medium text-grey-500"
        :class="{
          'text-grey-900': activeTabKey === key,
          'pointer-events-none': !tab.count,
          ...getAnimationProperty(key),
        }"
        @click="setActiveTab(key)"
      >
        {{ tab.label }} ({{ meta?.totals[key] || 0 }})
      </div>
    </div>
    <div v-if="loading" class="p-4">Searching...</div>
    <div v-else-if="hasNoResults" class="p-4">
      <div v-if="searchTerm?.length && searchTerm.length < 2">
        {{ $t("search.start_typing_to_search") }}
      </div>
      <div v-else>{{ $t("search.no_results_found") }}</div>
    </div>
    <div v-else class="flex min-w-0 flex-col gap-2 overflow-x-hidden p-2">
      <SafeNuxtLink
        v-for="result in currentlyShowingResults"
        :key="result.id"
        class="fade-in rounded-md p-2 hover:bg-semantic-info-50"
        :to="getResultLink(result.slug)"
        >{{ result.name
        }}<span v-if="activeTabKey === 'products'" class="text-grey-500">
          {{
            // @ts-ignore
            result?.partner?.name ? ` • ${result.partner.name}` : ""
          }}</span
        ></SafeNuxtLink
      >

      <div
        class="flex cursor-pointer items-center gap-2 rounded-md p-2 font-medium text-brand-600 hover:bg-semantic-info-50"
        @click="openResults"
      >
        <BaseIcon icon="search" :size="16" />
        {{ $t("search.show_all_results") }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  productLinkName,
  vendorLinkName,
  serviceLinkName,
} from "~/utils/linking";

type TabKey = "services" | "products" | "partners";
interface Props {
  results: null | OgApi.Search.ResultsSlim;
  meta: null | {
    totals: { services: number; products: number; partners: number };
  };
  loading: boolean;
  searchTerm?: string;
}

const props = defineProps<Props>();
const emit = defineEmits(["open-results"]);
const { t } = useI18n();

const activeTabKey = ref<TabKey | null>(null);
const lastActiveTabKey = ref<TabKey | null>(null);

watch(
  () => props.results,
  () => {
    nextTick(() => {
      if (props.results?.services.length) {
        activeTabKey.value = "services";
      } else if (props.results?.products.length) {
        activeTabKey.value = "products";
      } else if (props.results?.partners.length) {
        activeTabKey.value = "partners";
      } else {
        activeTabKey.value = null;
      }
    });
  },
  { immediate: true },
);

const hasNoResults = computed(() => {
  return (
    !props.results ||
    (props.results.services.length === 0 &&
      props.results.products.length === 0 &&
      props.results.partners.length === 0)
  );
});

const activeTab = computed(() => {
  return activeTabKey.value ? tabs.value[activeTabKey.value] : null;
});

const lastActiveTab = computed(() => {
  return lastActiveTabKey.value ? tabs.value[lastActiveTabKey.value] : null;
});

const currentlyShowingResults = computed(() => {
  if (!props.results) return [];
  if (!activeTabKey.value) return [];
  return (
    props.results[activeTabKey.value as keyof typeof props.results].slice(
      0,
      5,
    ) || []
  );
});

const tabs = computed(() => {
  return {
    services: {
      label: t("search.services"),
      count: props.results?.services.length || 0,
      order: 0,
    },
    products: {
      label: t("search.products"),
      count: props.results?.products.length || 0,
      order: 1,
    },
    partners: {
      label: t("search.partners"),
      count: props.results?.partners.length || 0,
      order: 2,
    },
  };
});

function getResultLink(slug: string) {
  if (activeTabKey.value === "services") {
    return { name: serviceLinkName(slug) };
  }
  if (activeTabKey.value === "products") {
    return { name: productLinkName(slug) };
  }
  if (activeTabKey.value === "partners") {
    return { name: vendorLinkName(slug) };
  }
  return "";
}

function getAnimationProperty(tabKey: any) {
  if (activeTabKey.value === tabKey) {
    if (
      lastActiveTab.value &&
      activeTab.value &&
      activeTab.value?.order < lastActiveTab.value?.order
    ) {
      return {
        "underline-in-left": true,
      };
    } else {
      return {
        "underline-in-right": true,
      };
    }
  }

  if (lastActiveTabKey.value === tabKey) {
    if (
      lastActiveTab.value &&
      activeTab.value &&
      lastActiveTab.value.order < activeTab.value.order
    ) {
      return {
        "underline-out-right": true,
      };
    } else {
      return {
        "underline-out-left": true,
      };
    }
  }

  return {
    "underline-none": true,
  };
}

function setActiveTab(key: TabKey) {
  if (key === activeTabKey.value) return;
  lastActiveTabKey.value = activeTabKey.value;
  activeTabKey.value = key;
}

function openResults() {
  emit("open-results");
}
</script>

<style scoped>
.underline-none::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background: #601efa;
  transform: scaleX(0);
  transition: transform 0.25s ease-out;
}

.underline-in-left::after,
.underline-in-right::after,
.underline-out-right::after,
.underline-out-left::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #601efa;
  transition: transform 0.25s ease-out;
}

.underline-in-left::after {
  transform: scaleX(1);
  transform-origin: bottom right;
}

.underline-in-right::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.underline-out-right::after {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.underline-out-left::after {
  transform: scaleX(0);
  transform-origin: bottom left;
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
