<template>
  <div class="pb-4" :class="{ 'pb-10': !hasHiddenText }">
    <div
      ref="descriptionText"
      class="relative overflow-hidden text-body text-grey-900"
      :class="descriptionClass"
      :style="`max-height: ${props.cutOff}px`"
    >
      <WysiwygRenderer :content="description" />
    </div>
    <BaseButton
      v-if="hasHiddenText"
      type="clear"
      :class="plainLayout ? '!px-0' : 'm-auto'"
      :color="buttonColor"
      size="small"
      @click="hasHiddenText = false"
    >
      {{ $t("service_page.read_more") }}
    </BaseButton>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  description: string;
  cutOff?: number;
  plainLayout?: boolean;
  buttonColor?: "purple-brand" | "black" | "white" | "black-900";
}

const props = withDefaults(defineProps<Props>(), {
  cutOff: 100,
  plainLayout: false,
  buttonColor: "purple-brand",
});

const hasHiddenText = ref<Boolean>(false);
const descriptionText = ref<HTMLElement | null>(null);
onMounted(() => {
  if (descriptionText.value instanceof HTMLElement) {
    descriptionText.value.scrollHeight > props.cutOff + 30
      ? (hasHiddenText.value = true)
      : (hasHiddenText.value = false);
  }
});

const descriptionClass = computed(() => {
  if (hasHiddenText.value) {
    if (props.plainLayout) {
      return "";
    }
    return "description--hidden";
  }
  return "description--showing";
});
</script>

<style scoped>
.description--hidden::after {
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  box-shadow: 0px -52px 22px -37px rgba(255, 255, 255, 1) inset;
  -webkit-box-shadow: 0px -52px 22px -37px rgba(255, 255, 255, 1) inset;
  -moz-box-shadow: 0px -52px 22px -37px rgba(255, 255, 255, 1) inset;
}

.description--showing {
  max-height: none !important;
  overflow: visible;
}
</style>
