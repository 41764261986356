<template>
  <div
    v-if="hasAnyContent"
    class="mb-8 flex items-center justify-between md:mb-10 lg:mb-12"
  >
    <h2 v-if="props.headerText" class="typo-h2">
      {{ props.headerText }}
    </h2>
    <NuxtLink
      v-if="props.headerLink && props.headerLinkText"
      class="typo-body-xl text-brand-600"
      :to="props.headerLink"
      >{{ props.headerLinkText }}</NuxtLink
    >
  </div>
</template>

<script setup>
const props = defineProps({
  headerText: {
    type: String,
    default: null,
  },
  headerLink: {
    type: String,
    default: null,
  },
  headerLinkText: {
    type: String,
    default: null,
  },
});

function hasAnyContent() {
  return props.headerText || (props.headerLink && props.headerLinkText);
}
</script>
