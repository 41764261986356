<template>
  <p class="typo-caption text-center text-grey-500">
    This site is protected by reCAPTCHA and the Google
    <a
      href="https://policies.google.com/privacy"
      class="font-medium"
      target="_blank"
      rel="noopener noreferrer"
      >Privacy&nbsp;Policy</a
    >
    and
    <a
      href="https://policies.google.com/terms"
      class="font-medium"
      target="_blank"
      rel="noopener noreferrer"
      >Terms&nbsp;of&nbsp;Service</a
    >
    apply.
  </p>
</template>
