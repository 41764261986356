<template>
  <div
    class="border-b border-b-grey-200 py-6"
    itemprop="offeredBy"
    itemscope
    itemtype="https://schema.org/Organization"
  >
    <div class="flex gap-2 md:gap-4">
      <div class="flex">
        <div
          class="flex h-[48px] w-[48px] shrink-0 items-center justify-center overflow-hidden rounded-full bg-grey-50 bg-cover bg-center text-body text-semantic-info-800"
          :style="vendorLogoStyles"
        >
          {{ vendor.images?.logo ? "" : initials(vendor.name) }}
        </div>
      </div>
      <div class="flex flex-1 flex-col gap-4">
        <div class="flex flex-1 gap-2 md:gap-4">
          <div class="flex flex-1 flex-col">
            <div>
              <NuxtLink
                :to="vendorProfilePath(vendor.slug)"
                class="text-body font-semibold"
                itemprop="mainEntityOfPage"
              >
                <span itemprop="name">{{ vendor.name }}</span>
              </NuxtLink>
            </div>
            <div>
              <div class="flex items-center">
                <SingleServiceVendorsServiceRating
                  v-if="vendor.rating.count"
                  :rating="vendor.rating"
                />
                <span v-else class="text-body text-grey-500">
                  {{ $t("request_flow.new_on_officeguru") }}
                </span>
                <div
                  v-if="vendor.content?.minimum_pax"
                  class="ml-2 flex items-center gap-2"
                >
                  <span class="text-grey-200">•</span>
                  <BaseIcon
                    icon="customer-app-persons_group"
                    class="text-grey-700"
                    :size="16"
                  />
                  <span class="typo-body text-grey-900">{{
                    $t("service_page.min_pax_count", {
                      count: vendor.content.minimum_pax,
                    })
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col-reverse items-end justify-end gap-4 md:flex-row md:items-start"
          >
            <BaseButton
              class="mr-[-16px] whitespace-nowrap md:mr-0"
              type="clear"
              color="black"
              size="small"
              :href="vendorProfilePath(vendor.slug)"
            >
              {{ $t("service_page.view") }}
            </BaseButton>
            <BaseButton
              v-if="!vendor.full_capacity"
              size="small"
              type="outline"
              class="ml-auto whitespace-nowrap"
              @click="
                () =>
                  showRequestFlow({
                    vendorId: vendor.id,
                    posthog: {
                      source: 'service_page',
                      trigger: 'list-button',
                    },
                  })
              "
              >{{ $t("request_flow.get_offer") }}
            </BaseButton>
          </div>
        </div>
        <div class="flex">
          <VendorBadges
            v-if="vendor.badges"
            :badges="vendor.badges"
            :full-capacity="vendor.full_capacity"
            class="flex-wrap"
          />
        </div>
        <div v-if="vendor.tags && vendor.tags.length > 1" class="flex">
          <div class="flex flex-wrap gap-2">
            <BaseTag v-for="tag in vendor.tags" :key="tag" :tag="tag" />
          </div>
          <meta itemprop="keywords" :content="vendor.tags.join(', ')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { initials } from "~/utils/initials.js";
import { useCmsImage } from "~/utils/use-cms-image.js";
import { useUIStore } from "~/store/UIStore.js";
import SingleServiceVendorsServiceRating from "~/pages/components/vendor/single-service-vendors-service-rating.vue";

const { showRequestFlow } = useUIStore();

interface Props {
  vendor: OgApi.VendorFull;
}

const props = defineProps<Props>();

const vendorLogoStyles = computed(() => {
  return {
    backgroundImage: `url('${getVendorImageUrl(props.vendor)}')`,
    border: "1px solid #e4e6eb",
  };
});

function getVendorImageUrl(vendor: OgApi.VendorFull) {
  const params = "width=48&height=48&fit=cover";

  // vendor images on detail are strings while on list they are objects :-|
  if (typeof vendor.images?.logo === "object") {
    return useCmsImage({
      src: vendor.images?.logo?.url || "",
      params,
    }).value;
  }

  return useCmsImage({
    src: vendor.images?.logo || "",
    params,
  }).value;
}
</script>
