// This passes all URL queries (currently only `embedded`) on to the next route.
// It didn't work to set `to.query` to `from.query` (not reflected in the URL), so I had to really manipulate
// the `to.fullPath` and attach the query string to it. It's not shown on <NuxtLink though, but picked up by
// the router so that the query sticks.
export default defineNuxtRouteMiddleware((to, from) => {
  const fromQuery = from.fullPath.split("?")[1];
  const toQuery = to.fullPath.split("?")[1];

  if (fromQuery && fromQuery.includes("embedded") && !toQuery) {
    to.fullPath = `${to.fullPath}?embedded`;
  }
});
