import {
  RequestFlowComponentsFieldsFieldAmount,
  RequestFlowComponentsFieldsFieldComposite,
  RequestFlowComponentsFieldsFieldDate,
  RequestFlowComponentsFieldsFieldFile,
  RequestFlowComponentsFieldsFieldRadio,
  RequestFlowComponentsFieldsFieldCheckbox,
  RequestFlowComponentsFieldsFieldSelect,
  RequestFlowComponentsFieldsFieldShortText,
  RequestFlowComponentsFieldsFieldTextarea,
  RequestFlowComponentsFieldsFieldWeekSelector,
} from "#components";

export function useGetComponentByQuestionType() {
  function getComponentByQuestionType(type: string) {
    const CMP_QUESTION_TYPE_MAP: Record<OgApi.ServiceQuestion["type"], any> = {
      amount: RequestFlowComponentsFieldsFieldAmount,
      composite: RequestFlowComponentsFieldsFieldComposite,
      date: RequestFlowComponentsFieldsFieldDate,
      file: RequestFlowComponentsFieldsFieldFile,
      radio: RequestFlowComponentsFieldsFieldRadio,
      checkbox: RequestFlowComponentsFieldsFieldCheckbox,
      select: RequestFlowComponentsFieldsFieldSelect,
      short_text: RequestFlowComponentsFieldsFieldShortText,
      textarea: RequestFlowComponentsFieldsFieldTextarea,
      week_selector: RequestFlowComponentsFieldsFieldWeekSelector,
    };

    const component =
      CMP_QUESTION_TYPE_MAP[type as OgApi.ServiceQuestion["type"]];

    if (!component) {
      // eslint-disable-next-line no-console
      console.warn(`Missing component for question type ${type.toString()}.`);
    }

    return component || null;
  }

  return {
    getComponentByQuestionType,
  };
}
