<template>
  <main :class="[!isEmbedded ? 'mt-16' : 'mt-[116px] md:mt-16']">
    <div
      v-for="block in pageData.content"
      :key="block.id"
      class="flex flex-col items-center"
    >
      <CmsBlockRenderer
        :block="block"
        :is-embedded="isEmbedded"
        :page-data="pageData"
      />
    </div>
  </main>
</template>

<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";

const { isEmbedded } = useUIStore();

const $route = useRoute();

const { data }: any = await useAsyncData($route.name as string, () => {
  return $fetch($route.meta.apiUrl as string);
});

useSeo(data?.value?.data?.seo);

const pageData: ComputedRef<{ content: any }> = computed(() => {
  return data?.value.data;
});
</script>
