<template>
  <div class="flex flex-col items-start">
    <div class="flex items-center">
      <div
        class="mr-3 flex h-[24px] w-[24px] items-center justify-center rounded-md text-white"
        :class="toastStyle.iconBoxClass"
      >
        <BaseIcon :size="16" :icon="toastStyle.icon" />
      </div>
      <div class="max-w-[90ch] flex-1 break-words text-body text-grey-900">
        {{ text }}
      </div>
    </div>
    <button
      v-if="subLink"
      class="ml-9 inline text-body font-medium text-brand-600"
      @click="linkClick"
    >
      {{ subLink?.label }}
    </button>
  </div>
</template>

<script lang="ts" setup>
import { ToastContentProps } from "vue3-toastify";

const router = useRouter();
interface Props {
  toastProps: ToastContentProps;
  text: string;
  type: "success" | "error";
  subLink?: {
    label: string;
    to?: { name: string };
    href?: string;
  };
}

const props = defineProps<Props>();

const toastStyle = computed<{ icon: BaseIconName; iconBoxClass: string }>(
  () => {
    if (props.type === "error") {
      return {
        icon: "close",
        iconBoxClass: "bg-semantic-negative-600",
      };
    }

    /* defaults to success */
    return {
      icon: "checkmark",
      iconBoxClass: "bg-semantic-positive-600",
    };
  },
);

function linkClick() {
  router.push(props.subLink?.to || props.subLink?.href || "/");
}
</script>
