<template>
  <div class="flex flex-col items-center py-36 text-center">
    <img :src="ladyShowingAllGood" alt="" loading="lazy" />
    <h3 class="typo-h4 mt-8 text-semantic-positive-600">
      {{ $t("customer_signup_form.we_are_happy_you_are_part_of_og") }}
    </h3>
    <p class="typo-body mt-4 text-grey-500">
      {{ $t("customer_signup_form.you_can_now_log_in") }}
    </p>
    <a :href="customerAppUrl" class="w-full">
      <BaseButton class="mt-5 w-full">{{
        $t("customer_signup_form.go_to_login")
      }}</BaseButton>
    </a>
  </div>
</template>

<script setup lang="ts">
import ladyShowingAllGood from "~/assets/lady_showing_all_good.svg?url";
const {
  public: { customerAppUrl },
} = useRuntimeConfig();
</script>
