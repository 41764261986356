<template>
  <BaseContent v-if="fieldOne || fieldTwo">
    <div class="flex flex-wrap md:flex-nowrap md:gap-x-12">
      <div v-if="fieldOne" class="w-full md:w-1/2">
        <CmsBlockRenderer
          :block="fieldOne"
          :page-data="props.content"
          :is-nested="isNested"
        />
      </div>
      <div v-if="fieldTwo" class="w-full md:w-1/2">
        <CmsBlockRenderer
          :block="fieldTwo"
          :page-data="props.content"
          :is-nested="isNested"
        />
      </div>
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
});

const isNested = computed(() => {
  return props.content.type === "2_column_components";
});

const fieldOne = computed(() => {
  return props.content.replicator_field?.[0];
});
const fieldTwo = computed(() => {
  return props.content.replicator_field?.[1];
});
</script>
