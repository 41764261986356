function hasProp(props: any, propName: string) {
  return Object.prototype.hasOwnProperty.call(props, propName);
}

export function useHasFieldError(props: any) {
  return computed(() => {
    if (!(hasProp(props, "errors") && hasProp(props, "fieldName"))) {
      throw new Error(
        "When using `useHasFieldError` you need `errors` and `fieldName` on your props.",
      );
    }

    return props.errors && Array.isArray(props.errors[props.fieldName]);
  });
}
