<template>
  <textarea
    class="typo-body w-full rounded-lg border border-grey-300 p-2.5 outline-none placeholder:text-grey-500 focus:border-grey-400"
    :class="{
      'border-semantic-negative-700 bg-semantic-negative-50': hasError,
    }"
    rows="3"
    type="text"
    :value="value"
    :placeholder="placeholder"
    @input="emit('update:value', ($event.target as HTMLTextAreaElement).value)"
  />
  <FormsComponentsFieldError
    :errors="errors"
    :field-name="fieldName"
    :error-name-space="errorNameSpace"
  />
</template>

<script setup lang="ts">
import { useHasFieldError } from "~/utils/use-has-field-error";
const emit = defineEmits(["update:value"]);
const props = defineProps({
  value: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  errors: {
    type: Object,
    default: null,
  },
  fieldName: {
    type: String,
    required: true,
  },
  errorNameSpace: {
    type: String,
    required: true,
  },
});

const hasError = useHasFieldError(props);
</script>
