const beforeUnloadCallback = (e: BeforeUnloadEvent) => {
  e.preventDefault();

  usePosthog().capture("Tab close initiated", {
    url: window.location.href,
  });

  // not shown on all browsers
  const confirmationMessage =
    "Are you sure you want to leave? Changes you made  will be lost.";

  (e || window.event).returnValue = confirmationMessage;
  return confirmationMessage;
};

const unloadCallback = () => {
  usePosthog().capture("Tab close confirmed", {
    url: window.location.href,
  });
};

export function useLeaveWarning() {
  function activate() {
    // sucks when developing with HMR, so disable it locally
    if (useRuntimeConfig().public.appEnv === "local") return;

    // make sure we can not register multiple times
    deactivate();

    window.addEventListener("beforeunload", beforeUnloadCallback);
    window.addEventListener("unload", unloadCallback);
  }

  function deactivate() {
    // sucks when developing with HMR, so disable it locally
    if (useRuntimeConfig().public.appEnv === "local") return;

    window.removeEventListener("beforeunload", beforeUnloadCallback);
    window.removeEventListener("unload", unloadCallback);
  }

  return {
    activate,
    deactivate,
  };
}
