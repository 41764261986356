<template>
  <BaseContent :style="backgroundColor">
    <ViewAllHeader
      :header-text="headerText"
      :header-link="headerLink"
      :header-link-text="headerLinkText"
    />
    <div class="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
      <NuxtLink
        v-for="service in props.content.services"
        :key="service.id"
        class="flex flex-col"
        :class="{ limited: props.content.limit_amount_of_elements }"
        :to="service.url"
      >
        <div class="aspect-3/2 w-full overflow-hidden rounded-xl bg-grey-100">
          <!-- done -->
          <CmsImage
            v-if="service.thumbnail?.url"
            :src="service.thumbnail.url"
            :alt="service.thumbnail.alt"
            :width="service.thumbnail.width"
            :height="service.thumbnail.height"
            class="aspect-3/2 object-cover"
            :max-width="527"
          />
        </div>
        <h3 class="typo-body-xl mt-3 font-semibold">
          {{ service.title }}
        </h3>
        <p class="typo-body-xl text-grey-700">
          {{ service.excerpt }}
        </p>
      </NuxtLink>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
import { useBackgroundColor } from "~/components/cms-blocks/components/use-background-color";

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
  raw: {
    type: Object,
    required: true,
  },
});

const backgroundColor = useBackgroundColor(props);

const headerText = computed(() => props.content.headline);
const headerLink = computed(() => {
  if (
    !(Array.isArray(props.content.header_link) && props.content.header_link[0])
  )
    return false;
  return props.content.header_link[0].url;
});
const headerLinkText = computed(() => {
  if (
    !(Array.isArray(props.content.header_link) && props.content.header_link[0])
  )
    return false;
  return props.content.header_link[0].title;
});
</script>

<style>
.limited:nth-child(n + 4) {
  display: none;
}
@media screen(md) {
  .limited:nth-child(n) {
    display: flex;
  }
  .limited:nth-child(n + 5) {
    display: none;
  }
}
@media screen(lg) {
  .limited:nth-child(n) {
    display: flex;
  }
  .limited:nth-child(n + 9) {
    display: none;
  }
}
</style>
