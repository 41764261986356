<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="flex flex-col">
    <template v-for="(field, index) in question.content.fields" :key="index">
      <label class="my-1 block cursor-pointer py-1.5">
        <input
          v-model="question.answer"
          type="checkbox"
          :value="field.name"
          class="hidden"
        />
        <div class="flex gap-2.5 rounded-md">
          <div
            class="flex h-6 w-6 shrink-0 items-center justify-center rounded-md transition-colors duration-500"
            :class="
              question.answer?.includes(field.name)
                ? 'bg-brand-600'
                : 'bg-white border border-grey-300'
            "
          >
            <BaseIcon icon="checkmark" class="text-white" :size="12" />
          </div>
          <span class="typo-body truncate">
            {{ field.name }}
          </span>
        </div>
      </label>
    </template>
  </div>
</template>

<script setup lang="ts">
/* eslint-disable vue/no-mutating-props */

const props = defineProps<{
  question: OgApi.ServiceQuestionWithAnswer;
}>();

onMounted(() => {
  // assign default (+ default type Array)
  if (!props.question.answer) {
    props.question.answer = [];
  }
});
</script>
