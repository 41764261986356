import { markRaw } from "vue";
import { Router } from "vue-router";
import { PageRenderer } from "#components";
import SingleService from "~/pages/single-service.vue";
import SingleVendor from "~/pages/single-vendor.vue";
import SingleBlog from "~/pages/single-blog.vue";
import SingleProduct from "~/pages/components/single-product.vue";
import SingleVendorProductsList from "~/pages/single-vendor-products-list.vue";
import { linkName } from "~/utils/linking";

const handleMap: { [key: string]: Component } = {
  services: markRaw(SingleService),
  vendors: markRaw(SingleVendor),
  blogs: markRaw(SingleBlog),
  products: markRaw(SingleProduct),
  productList: markRaw(SingleVendorProductsList),
  cmsPage: markRaw(PageRenderer),
};

export default defineNuxtPlugin(async (nuxtApp) => {
  const router = nuxtApp.$router as Router;
  const config = nuxtApp.$config as any;

  (await getRoutesFromCms(config)).forEach((o: CmsCollectionProp) => {
    const isService = o.collection.handle === "services";
    const isVendor = o.collection.handle === "vendors";
    const isProduct = o.collection.handle === "products";

    const route = {
      name: generateName(o),
      path: o.url,
      component: handleMap[o.collection.handle] || handleMap.cmsPage,
      meta: {
        apiUrl: o.api_url,
        ...(isService ? { serviceApiId: o.api_id, serviceSlug: o.slug } : {}),
        ...(isVendor ? { vendorApiId: o.api_id, vendorSlug: o.slug } : {}),
        ...(isProduct
          ? {
              productApiId: o.api_id,
              productSlug: o.slug,
              partnerApiId: o.partner_api_id,
            }
          : {}),
      },
    };
    router.addRoute("routes", route);

    if (isVendor) {
      const productListRoute = {
        name: `vendors-${o.slug}-products`,
        path: `${o.url}/${globalThis.lang === "en" ? "products" : "produkter"}`,
        component: handleMap.productList,
        meta: {
          apiUrl: o.api_url,
          vendorApiId: o.api_id,
          vendorSlug: o.slug,
        },
      };
      router.addRoute("routes", productListRoute);
    }
  });

  router.addRoute("routes", {
    name: "index",
    path: "/",
    redirect: `/${globalThis.lang}`,
  } as any);

  router.addRoute("routes", {
    name: "search",
    path: globalThis.lang === "en" ? "/en/search" : "/da/soeg",
    component: () => import("~/pages/single-search.vue"),
  });

  router.addRoute("routes", {
    name: "cart",
    path: globalThis.lang === "en" ? "/en/cart" : "/da/kurv",
    component: () => import("~/pages/single-cart.vue"),
  });

  router.addRoute("routes", {
    name: "checkout",
    path: globalThis.lang === "en" ? "/en/checkout" : "/da/kasse",
    component: () => import("~/pages/single-checkout.vue"),
  });
});

async function getRoutesFromCms(config: any): Promise<CmsCollectionProp[]> {
  const data = await useNuxtApp().runWithContext(() => {
    const url = `${config.public.cmsUrl}api/collections-aggregated`;
    // eslint-disable-next-line no-console
    if (import.meta.server) console.log(`Requesting ${url}`);
    return useFetch(url);
  });

  const tree = data.data.value as Record<string, CmsCollectionProp[]>;

  // no blogs in english, so also no route generation
  if (globalThis.lang !== "da") tree.blogs = [];

  return Object.values(tree).flat();
}

function generateName(page: any) {
  // name for resources are generated like this, to make it easier to link to things with just their slug
  if (page.collection.handle === "pages") return page.url;
  return linkName(page.collection.handle, page.slug);
}
