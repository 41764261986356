<template>
  <div :class="{ 'sticky top-32': sticky }">
    <div class="rounded-xl p-6 shadow-totally-overcomplicated">
      <h3 class="typo-h5 mb-4">
        <template v-if="serviceName">
          {{
            $t("request_flow.get_offer_for_service", { service: serviceName })
          }}
        </template>
        <template v-else-if="vendorName">
          {{
            $t("request_flow.get_offer_from_vendor", { vendor: vendorName })
          }}</template
        >
      </h3>
      <div v-if="responseTimeText">
        <span
          class="typo-body mb-4 inline-flex items-start justify-center rounded-lg border border-grey-100 bg-grey-50 px-1.5 text-grey-500"
        >
          <BaseIcon icon="clock" :size="12" class="mr-1 mt-[5px]" />{{
            responseTimeText
          }}
        </span>
      </div>
      <span class="typo-body text-grey-500">
        {{ $t("request_flow.we_will_ask_few_questions") }}
      </span>
      <template v-if="!$route.meta.serviceSlug">
        <div class="typo-body mt-4 text-grey-700">
          {{ $t("request_flow.choose_service") }}
        </div>
        <select
          v-model="model.service"
          class="typo-body mt-1 w-full rounded-lg border border-grey-300 p-2.5 text-grey-700"
          :class="{
            'border-semantic-negative-600 bg-semantic-negative-50': error,
          }"
        >
          <option disabled value="">
            {{ $t("request_flow.select_service") }}
          </option>
          <option
            v-for="service in filteredServices"
            :key="service.id"
            :value="service.id"
          >
            {{ service.name }}
          </option>
        </select>
        <span v-if="error" class="typo-caption mt-1 text-semantic-negative-600">
          <BaseIcon class="mr-1.5" icon="close" :size="10" />{{
            $t("request_flow.select_service_for_offer")
          }}
        </span>
      </template>
      <BaseButton class="mt-6 block w-full" @click="startShowRequestFlow">{{
        $t("request_flow.get_an_offer")
      }}</BaseButton>
      <span class="typo-caption mt-3 block text-center text-grey-500">{{
        $t("request_flow.free_wo_strings")
      }}</span>
    </div>
    <BaseTooltip
      :text="$t('service_page.officeguru_protection_description')"
      anchor-top
      full-width
    >
      <div
        class="mt-8 flex items-center justify-center gap-2 text-center text-body font-medium text-grey-700"
      >
        <BaseIcon icon="protection" />{{
          $t("service_page.officeguru_protection")
        }}
      </div>
    </BaseTooltip>
    <!-- TODO: This should probably live somewhere else now that we call it from somewhere else as well -->
    <Teleport v-if="requestFlowIsShown" to="[data-teleport=request-flow]">
      <RequestFlow @close="hideRequestFlow" />
    </Teleport>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useUIStore } from "~/store/UIStore";
import { useResponseTime } from "~/utils/use-response-time";

const { requestFlowIsShown } = storeToRefs(useUIStore());
const { showRequestFlow, hideRequestFlow } = useUIStore();

interface Props {
  sticky: boolean;
  services?: OgApi.Service[];
  vendorName?: string;
  responseTime?: number | null;
  serviceName?: string;
  posthogLocation: string;
}
const props = defineProps<Props>();

const model = ref({
  service: "",
});

const error = ref(false);

watchEffect(() => {
  if (model.value.service) {
    error.value = false;
  }
});

const { responseTimeText } = useResponseTime(props.responseTime);
const isValid = computed(() => {
  return !!(useRoute().meta.serviceSlug || model.value.service);
});

const filteredServices = computed(() => {
  return (
    props.services?.filter((service: OgApi.Service) => {
      return service.show_in_request_flow;
    }) || []
  );
});

function startShowRequestFlow() {
  if (!isValid.value) {
    error.value = true;
    return;
  }

  error.value = false;

  showRequestFlow({
    serviceId: useRoute().meta.serviceSlug || model.value.service,
    posthog: { trigger: "get-offer-box", source: props.posthogLocation },
  });
}
</script>
